import axios from 'axios'
import config from '../config/config'

const post = (resource, data, contentType) => axios.post(`${config.apiUrl}${resource}`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    'Content-Type': contentType || 'application/json',
  },
})

const put = (resource, data) => axios.put(`${config.apiUrl}${resource}`, data, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
    'Content-Type': 'application/json',
  },
})

const get = (resource) => axios.get(`${config.apiUrl}${resource}`, {
  headers: {
    Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
  },
})

export default { post, put, get }
