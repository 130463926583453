import React from 'react'
import {
  ArrayField, Datagrid, DateField, NumberField, Show, SimpleShowLayout, TextField,
} from 'react-admin'
import { dateAndTime } from '../../helpers/dateRender'

const PriceIncreaseRateShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="id" label="Identifiant" />
      <TextField source="year" label="Année de l'augmentation (année de début des contrat impactés)" />
      <NumberField
        source="priceIncreaseRatePercentage"
        label="Augmentation du prix (en %)"
        locale="fr-FR"
        options={{
          maximumFractionDigits: 2,
        }}
      />
      <ArrayField source="modificationHistory">
        <Datagrid>
          <DateField locales="fr-FR" source="date" label="Date" options={dateAndTime} />
          <NumberField
            source="priceIncreaseRatePercentage"
            label="Augmentation du prix (en %)"
            locale="fr-FR"
            options={{
              maximumFractionDigits: 2,
            }}
          />
        </Datagrid>
      </ArrayField>
    </SimpleShowLayout>
  </Show>
)

export default PriceIncreaseRateShow
