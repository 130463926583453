export const paymentReminderMode = {
  SMS: 'SMS',
  EMAIL: 'EMAIL',
  PHONE_CALL: 'PHONE_CALL',
  FORMAL_NOTICE: 'FORMAL_NOTICE',
  ORDER_FOR_PAYMENT: 'ORDER_FOR_PAYMENT',
  DO_NOT_REMIND: 'DO_NOT_REMIND',
}

export const paymentReminderModesLabels = {
  [paymentReminderMode.SMS]: 'SMS',
  [paymentReminderMode.EMAIL]: 'Email',
  [paymentReminderMode.PHONE_CALL]: 'Appel téléphonique',
  [paymentReminderMode.FORMAL_NOTICE]: 'Mise en demeure',
  [paymentReminderMode.ORDER_FOR_PAYMENT]: 'Injonction de payer',
  [paymentReminderMode.DO_NOT_REMIND]: 'Ne pas relancer',
}

export const paymentReminderType = {
  FIRST: 'FIRST',
  SECOND: 'SECOND',
  THIRD: 'THIRD',
  FORMAL_NOTICE: 'FORMAL_NOTICE',
  ORDER_FOR_PAYMENT: 'ORDER_FOR_PAYMENT',
  DO_NOT_REMIND: 'DO_NOT_REMIND',
}

export const paymentReminderTypesLabels = {
  [paymentReminderType.FIRST]: '1er rappel',
  [paymentReminderType.SECOND]: '2ème rappel',
  [paymentReminderType.THIRD]: '3ème rappel',
  [paymentReminderType.FORMAL_NOTICE]: 'Mise en demeure',
  [paymentReminderType.ORDER_FOR_PAYMENT]: 'Injonction de payer',
  [paymentReminderType.DO_NOT_REMIND]: 'Ne pas relancer',
}
