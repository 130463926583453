export const IDENTIFIERS = {
  CUSTOMER: 'customers',
  PROFESSIONAL: 'professionals',
  PURCHASE_REQUEST: 'purchaserequests',
  PURCHASE: 'purchases',
  PURCHASE_REQUEST_PROFESSIONAL: 'purchaserequestsprofessional',
  BUSINESS_LINE: 'businesslines',
  ENUM: 'enums',
  NOTIFICATIONS: 'notifications',
  APPOINTMENTS: 'appointments',
  APPOINTMENT_VISIT: 'appointmentvisits',
  APPOINTMENT_INTERVENTION: 'appointmentinterventions',
  STATISTIC: 'statistics',
  REACTIVITY_1: 'reactivity1',
  REACTIVITY_2: 'reactivity2',
  PURCHASE_MATURITY: 'purchasematurity',
  INVOICES_1_BIS: 'invoices',
  BILLINGS: 'billings',
  WALLET: 'wallets',
  PARAMETERS: 'parameters',
  MENU_PARAMETERS: 'menuparameters',
  OFFRE_1BIS: 'customers1bis',
  AVANCE_IMMEDIATE: 'avanceimmediate',
  AVANCE_IMMEDIATE_TRANSACTIONS: 'avanceimmediatetransactions',
  COMMERCIAL_AREAS: 'commercialareas',
  COUPON_CODES: 'couponcodes',
  ZIPCODE_DESCRIPTIONS: 'zipcodedescriptions',
  PRICE_INCREASE_RATES: 'priceincreaserates',
  ORDER: 'orders',
}

export const resources = {
  [[IDENTIFIERS.CUSTOMER]]: {
    name: IDENTIFIERS.CUSTOMER,
    url: IDENTIFIERS.CUSTOMER,
  },
  [[IDENTIFIERS.PROFESSIONAL]]: {
    name: IDENTIFIERS.PROFESSIONAL,
    url: IDENTIFIERS.PROFESSIONAL,
  },
  [[IDENTIFIERS.REACTIVITY_1]]: {
    name: IDENTIFIERS.REACTIVITY_1,
    url: 'reactivity/notAnsweredToRequest',
  },
  [[IDENTIFIERS.REACTIVITY_2]]: {
    name: IDENTIFIERS.REACTIVITY_2,
    url: 'reactivity/notFinishedVisits',
  },
  [[IDENTIFIERS.PURCHASE_MATURITY]]: {
    name: IDENTIFIERS.PURCHASE_MATURITY,
    url: 'purchase-maturities',
  },
  [[IDENTIFIERS.INVOICES_1_BIS]]: {
    name: IDENTIFIERS.INVOICES_1_BIS,
    url: 'offre1bis/invoices',
  },
  [[IDENTIFIERS.BILLINGS]]: {
    name: IDENTIFIERS.BILLINGS,
    url: 'billings',
  },
  /**
   * PURCHASES REQUESTS
   */
  [[IDENTIFIERS.PURCHASE_REQUEST]]: {
    name: IDENTIFIERS.PURCHASE_REQUEST,
    url: IDENTIFIERS.PURCHASE_REQUEST,
  },
  /**
   * APPOINTMENTS
   */
  [[IDENTIFIERS.APPOINTMENT_VISIT]]: {
    name: IDENTIFIERS.APPOINTMENT_VISIT,
    url: `${IDENTIFIERS.APPOINTMENTS}/visits`,
  },
  [[IDENTIFIERS.STATISTIC]]: {
    name: IDENTIFIERS.STATISTIC,
    url: IDENTIFIERS.STATISTIC,
  },
  [[IDENTIFIERS.APPOINTMENT_INTERVENTION]]: {
    name: IDENTIFIERS.APPOINTMENT_INTERVENTION,
    url: `${IDENTIFIERS.APPOINTMENTS}/interventions`,
  },
  [[IDENTIFIERS.PURCHASE]]: {
    name: IDENTIFIERS.PURCHASE,
    url: IDENTIFIERS.PURCHASE,
  },
  [[IDENTIFIERS.PURCHASE_REQUEST_PROFESSIONAL]]: {
    name: IDENTIFIERS.PURCHASE_REQUEST_PROFESSIONAL,
    url: IDENTIFIERS.PURCHASE_REQUEST_PROFESSIONAL,
  },
  [[IDENTIFIERS.BUSINESS_LINE]]: {
    name: IDENTIFIERS.BUSINESS_LINE,
    url: IDENTIFIERS.BUSINESS_LINE,
  },
  [[IDENTIFIERS.ENUM]]: {
    name: IDENTIFIERS.ENUM,
    url: IDENTIFIERS.ENUM,
  },
  [[IDENTIFIERS.NOTIFICATIONS]]: {
    name: IDENTIFIERS.NOTIFICATIONS,
    url: IDENTIFIERS.NOTIFICATIONS,
  },
  [[IDENTIFIERS.WALLET]]: {
    name: IDENTIFIERS.WALLET,
    url: IDENTIFIERS.WALLET,
  },
  [[IDENTIFIERS.PARAMETERS]]: {
    name: IDENTIFIERS.PARAMETERS,
    url: IDENTIFIERS.PARAMETERS,
  },
  [[IDENTIFIERS.MENU_PARAMETERS]]: {
    name: IDENTIFIERS.MENU_PARAMETERS,
    url: 'menuparameters',
  },
  [[IDENTIFIERS.OFFRE_1BIS]]: {
    name: 'Clients Offre1bis',
    url: 'offre1bis/customers',
  },
  [[IDENTIFIERS.AVANCE_IMMEDIATE]]: {
    name: 'Avance immédiate',
    url: 'avance-immediate',
  },
  [[IDENTIFIERS.AVANCE_IMMEDIATE_TRANSACTIONS]]: {
    name: 'Avance immédiate transactions',
    url: 'avance-immediate/transactions',
  },
  [[IDENTIFIERS.COMMERCIAL_AREAS]]: {
    name: 'Régions commerciales',
    url: 'commercial-areas',
  },
  [[IDENTIFIERS.COUPON_CODES]]: {
    name: 'Codes avantage',
    url: 'coupon-codes',
  },
  [[IDENTIFIERS.ZIPCODE_DESCRIPTIONS]]: {
    name: 'Descriptions villes',
    url: 'zipcode-descriptions',
  },
  [[IDENTIFIERS.PRICE_INCREASE_RATES]]: {
    name: "Taux d'augmentation de prix",
    url: 'price-increase-rates',
  },
  [[IDENTIFIERS.ORDER]]: {
    name: IDENTIFIERS.ORDER,
    url: 'orders',
  },
}

/**
 * Returns the URL according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceUrl = (name) => {
  if (resources[name]) {
    return resources[name].url
  }
  return name
}

/**
 * Returns the label according to the resource, or the original parameter if it's not found
 * @param {string} name Resource name
 */
export const getResourceLabel = (name) => {
  if (resources[name]) {
    return resources[name].name
  }
  return name
}

export default {
  IDENTIFIERS,
  resources,
  getResourceLabel,
  getResourceUrl,
}
