import React from 'react'
import {
  Datagrid,
  DateField,
  EditButton,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TopToolbar,
} from 'react-admin'
import customerPositionings from './CustomerPositionings'
import ExportModalView from '../../export/ExportModalView'

const ExportActions = () => (
  <TopToolbar>
    <ExportModalView path="purchases" />
  </TopToolbar>
)

const CustomerPositioning = ({ record, source }) => {
  const untranslatedCustomerPositioning = record[source]
  const translatedCustomerPositioning = customerPositionings.find(
    (customerPosition) => customerPosition?.id === untranslatedCustomerPositioning
  )
  return <span>{translatedCustomerPositioning?.value}</span>
}

const PurchaseList = (props) => (
  <List {...props} sort={{ field: 'createdDate', order: 'DESC' }} perPage={15} actions={<ExportActions />}>
    <Datagrid rowClick="show">
      <ReferenceField label="id" link="show" source="id" reference="purchases">
        <TextField source="id" />
      </ReferenceField>
      <DateField locales="fr-FR" source="createdDate" showTime label="Date de création" />
      <TextField source="status" label="Statut" />
      <CustomerPositioning source="customerPositioning" label="Positionnement Client" />
      <TextField source="nbMaturities" label="Echéances" />
      <TextField source="serviceLevel" label="Offre" />
      <ReferenceField label="Demande" link="show" source="purchaseRequest" reference="purchaserequests">
        <TextField source="id" />
      </ReferenceField>
      <ReferenceField label="Vendeur" link="show" source="sellerEntityRelation" reference="professionals">
        <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
      </ReferenceField>
      <ReferenceField label="Acheteur" link="show" source="buyerEntityRelation" reference="customers">
        <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
      </ReferenceField>
      <NumberField source="priceHT" label="Prix HT" options={{ style: 'currency', currency: 'EUR' }} />
      <NumberField source="priceTTC" label="Prix TTC" options={{ style: 'currency', currency: 'EUR' }} />
      <EditButton />
    </Datagrid>
  </List>
)

export default PurchaseList
