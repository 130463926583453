import React from 'react'
import {
  Create, TextInput, NumberInput, BooleanInput, SimpleForm,
} from 'react-admin'

const MenuParametersCreate = (props) => (
  <Create {...props}>
    <SimpleForm>
      <TextInput source="title" label="Titre" />
      <TextInput source="link" label="Lien URL" />
      <NumberInput source="priority" label="Priorité" />
      <BooleanInput source="webview" label="Webview ?" />
    </SimpleForm>
  </Create>
)

export default MenuParametersCreate
