import React, { useState } from 'react'
import {
  ArrayField,
  BooleanField,
  Button,
  Datagrid,
  DateField,
  EmailField,
  FileField,
  FunctionField,
  ImageField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  Show,
  ShowButton,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
  useShowController,
} from 'react-admin'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import { dateAndTimeWithWeekday } from '../../helpers/dateRender'
import dataProvider from '../../helpers/dataProvider'
import { IDENTIFIERS, IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'
import AvanceImmediateInscriptionFormModalView from '../avanceimmediate/components/AvanceImmediateInscriptionFormModalView'
import ToggleUseAvanceImmediateButton from '../avanceimmediate/components/ToggleUseAvanceImmeidateButton'
import AvanceImmediateInscriptionErrorModalView from '../avanceimmediate/components/AvanceImmediateInscriptionErrorModalView'
import PayRewardModal from '../avanceimmediate/components/PayRewardModal'
import { sumArray } from '../../helpers/ArrayHelpers'
import AddRewardPointsModal from '../avanceimmediate/components/AddRewardPointsModal'
import { rewardPointsEarningType } from '../../enums/RewardPointsEarningType'
import AvanceImmediateInscriptionLinkToOtherInscriptionModalView from '../avanceimmediate/components/AvanceImmediateInscriptionLinkToOtherInscriptionModalView'

const CustomerShow = (props) => {
  const { record } = useShowController(props)
  const [resetPasswordDate, setResetPasswordDate] = useState()
  const [passwordReset, setPasswordReset] = useState(false)

  const ConnectAsLink = ({ record: connectAsLinkRecord, source: connectAsLinkSource, label: connectAsLinkLabel }) => connectAsLinkRecord[connectAsLinkSource] ? (
    <a href={connectAsLinkRecord[connectAsLinkSource]} target="_blank" rel="noopener noreferrer">
      {connectAsLinkLabel}
    </a>
  ) : (
    <div />
  )

  const handleResetPassword = () => {
    dataProvider.post(`/customers/${props.id}/reset-password`).then((response) => {
      if (response.status === 200) {
        setResetPasswordDate(new Date())
        setPasswordReset(true)
      }
    })
  }

  const resetButton = () => {
    if (!passwordReset) {
      return (
        <Button onClick={handleResetPassword} label="Envoyer le mail de nouveau mot de passe">
          <MailOutlineIcon />
        </Button>
      )
    }
    return (
      <>
        {resetPasswordDate.toLocaleDateString()} MDP envoyé à {resetPasswordDate.toLocaleTimeString()}
      </>
    )
  }
  const infoProvided = record && record.avanceImmediate && record.avanceImmediate.informationProvided === true
  const isRewardHistoryEarnings = record
    && record.rewardPointsHistory
    && record.rewardPointsHistory.earnings
    && record.rewardPointsHistory.earnings.length > 0
  const isRewardHistoryUsages = record
    && record.rewardPointsHistory
    && record.rewardPointsHistory.usages
    && record.rewardPointsHistory.usages.length > 0
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Informations personnelles">
          {resetButton()}
          <ConnectAsLink source="connectAsUrl" label="Se connecter au site" />
          <TextField source="id" label="Identifiant" />
          <TextField source="entityRelation.sageId" label="SAGE ID" />
          <TextField source="customer.status" label="État" />
          <TextField source="profile.lastName" label="Nom" />
          <TextField source="profile.firstName" label="Prénom" />
          <DateField locales="fr-FR" source="createdDate" options={dateAndTimeWithWeekday} label="Date de création" />
          <EmailField source="account.email" label="Adresse mail" />

          <TextField source="customer.address.streetAndNumber" label="N° et rue" />
          <TextField source="customer.address.zipCode" label="Code postal" />
          <TextField source="customer.address.city" label="Ville" />
          <TextField source="customer.address.region" label="Région" />
          <TextField source="customer.address.commercialArea" label="Zone commerciale" />

          <DateField locales="fr-FR" source="profile.birthdate" label="Date de Naissance" />

          <TextField source="profile.country" label="Pays" />
          <TextField source="profile.nationality" label="Nationalité" />

          <NumberField source="customer.gardenSurface" label="Surface du jardin (m²)" />
          <TextField source="profile.phone" label="Téléphone" />
          <RichTextField source="customer.comment" label="Commentaires" />
          <BooleanField source="profile.subscribedNewsletter" label="Newsletter" />
          <BooleanField source="profile.subscribedNotification" label="Notifications mail" />
          <BooleanField source="profile.subscribedSms" label="Notifications SMS" />
          <BooleanField source="profile.shouldSendLetter" label="Envoi mails facture/devis par lettre" />
          <ImageField source="profile.pictureFile" label="Photo de profil" />
          <ReferenceField
            label="Parrain"
            link="show"
            source="relatedProfessional"
            reference={RESOURCE_IDENTIFIERS.PROFESSIONAL}
          >
            <TextField source="profile.fullname" />
          </ReferenceField>
          <ReferenceField
            link="show"
            label="Code avantage"
            source="customer.appliedCouponCodeId"
            reference={RESOURCE_IDENTIFIERS.COUPON_CODES}
          >
            <TextField source="name" />
          </ReferenceField>
          <ArrayField source="entityRelation.fees" label="Frais de service">
            <Datagrid>
              <TextField source="productFamilyId" label="Code" />
              <NumberField source="value" label="Valeur" />
            </Datagrid>
          </ArrayField>
        </Tab>
        <Tab label="Wallet">
          <ReferenceField label="Identifiant" link="show" source="wallet.id" reference="wallets">
            <TextField source="idProviderReference" label="Identifiant ATJ (id externe)" />
          </ReferenceField>
          <TextField source="wallet.idProviderTechnical" label="Identifiant Lemonway" />
          <UrlField source="wallet.lemonwayUrl" label="Lien lemonway" target="_blank" />
          <TextField source="wallet.status" label="Statut" />
          <NumberField source="wallet.balance" label="Solde" options={{ style: 'currency', currency: 'EUR' }} />
          <BooleanField source="wallet.active" label="Actif" />
        </Tab>
        <Tab label="Historique des demandes">
          <ReferenceManyField
            reference="purchaserequests"
            sort={{ field: 'createdDate', order: 'DESC' }}
            target="customerEntityRelation"
            addLabel={false}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show">
              <ReferenceField label="Demande" link="show" source="id" reference="purchaserequests">
                <TextField source="id" />
              </ReferenceField>
              <TextField source="businessLine" label="Prestation" />
              <BooleanField source="visitRequired" label="Avec visite" />
              <TextField source="status" label="État" />
              <DateField locales="fr-FR" showTime source="createdDate" label="Date de création" />
              <DateField locales="fr-FR" source="nextAppointment" label="Prochain RDV" />

              <NumberField source="visitCounter.numberOfWaitingResponse" label="Visites en attente" />
              <NumberField source="visitCounter.numberOfAcceptResponse" label="Visites acceptées" />
              <NumberField source="visitCounter.numberOfTerminatedResponse" label="Visites terminées" />
              <NumberField source="visitCounter.numberOfCancelledResponse" label="Visites annulées" />

              <NumberField source="interventionCounter.numberOfWaitingResponse" label="Devis en attente" />
              <NumberField source="interventionCounter.numberOfAcceptResponse" label="Devis acceptés" />
              <NumberField source="interventionCounter.numberOfTerminatedResponse" label="Devis terminés" />
              <NumberField source="interventionCounter.numberOfCancelledResponse" label="Devis annulés" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Historique des devis">
          <ReferenceManyField
            reference="purchases"
            sort={{ field: 'createdDate', order: 'DESC' }}
            addLabel={false}
            target="customerEntityRelation"
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="id" />
              <ReferenceField label="Demande" link="show" source="purchaseRequest" reference="purchaserequests">
                <TextField source="id" />
              </ReferenceField>
              <DateField locales="fr-FR" source="createdDate" label="Date de création" />
              <ReferenceField label="Professionel" link="show" source="sellerEntityRelation" reference="professionals">
                <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
              </ReferenceField>
              <TextField source="status" label="État" />
              <TextField source="businessLine.businessLineType" label="Business Line" />
              <FileField source="quote.path" title="Devis" target="_blank" label="Devis" />
              <NumberField source="unitPrice" label="Prix app" options={{ style: 'currency', currency: 'EUR' }} />
              <NumberField source="priceTTC" label="Prix TTC" options={{ style: 'currency', currency: 'EUR' }} />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>
        <Tab label="Avance immédiate">
          <BooleanField label="Informations envoyées" source="avanceImmediate.informationProvided" />
          <AvanceImmediateInscriptionFormModalView customerId={props.id} />
          <AvanceImmediateInscriptionErrorModalView />
          <AvanceImmediateInscriptionLinkToOtherInscriptionModalView customerId={props.id} />
          {infoProvided && (
            <BooleanField source="avanceImmediate.status.ready" label="Avance immédiate prête à utilisation" />
          )}
          {infoProvided && <TextField source="avanceImmediate.customerEmail" label="Email URSSAF" />}
          {infoProvided && (
            <TextField source="avanceImmediate.status.description" label="Description du statut par l'URSSAF" />
          )}
          <ToggleUseAvanceImmediateButton />
        </Tab>
        <Tab label="Fidélisation">
          {isRewardHistoryEarnings && (
            <ArrayField source="rewardPointsHistory.earnings" label="Gains de points de fidélité">
              <Datagrid>
                <DateField locales="fr-FR" source="createdDate" label="Date d'obtention" />
                <ReferenceField label="Facture liée" link={false} source="order" reference={IDENTIFIERS.ORDER}>
                  <FunctionField
                    render={(record) => (
                      <a href={`/#/${IDENTIFIERS.PURCHASE_REQUEST}/${record.idPurchaseRequest}/show`}>
                        {record.invoiceNumber}
                      </a>
                    )}
                  />
                </ReferenceField>
                <FunctionField label="Type" render={(record) => rewardPointsEarningType[record.type]} />
                <TextField source="description" label="Description" />
                <NumberField locales="fr-FR" source="points" label="Points" />
                <FunctionField label="Utilisation des points" render={(record) => record.usedPointsList.join(' | ')} />
                <FunctionField
                  label="Points restants"
                  render={(record) => record.points - sumArray(record.usedPointsList)}
                />
                <DateField locales="fr-FR" source="expirationDate" label="Date d'expiration" />
                <BooleanField source="expired" label="Expiré" />
              </Datagrid>
            </ArrayField>
          )}
          <AddRewardPointsModal />
          {isRewardHistoryUsages && (
            <ArrayField source="rewardPointsHistory.usages" label="Dépenses de points de fidélité">
              <Datagrid>
                <DateField locales="fr-FR" source="createdDate" label="Date de dépense" />
                <NumberField locales="fr-FR" source="points" label="Points" />
                <TextField source="description" label="Description" />
              </Datagrid>
            </ArrayField>
          )}
          <NumberField locales="fr-FR" source="rewardPointsHistory.availablePoints" label="Points disponibles" />
          <PayRewardModal />
        </Tab>
        <Tab label="Emails/SMS">
          <ReferenceManyField
            reference="notifications"
            sort={{ field: 'createdDate', order: 'DESC' }}
            addLabel={false}
            target="entity"
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="provider" label="Type" />
              <TextField source="status" label="Statut" />
              <DateField locales="fr-FR" showTime source="createdDate" label="Date de création" />
              <TextField source="type" label="Catégorie" />
              <TextField source="subject" label="Objet" />
              <RichTextField source="content" />
              <ArrayField source="attachments" label="PJ">
                <Datagrid>
                  <FileField source="path" target="_blank" title="filename" label="PJ" />
                </Datagrid>
              </ArrayField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default CustomerShow
