import React from 'react'
import {
  Show, Tab, TabbedShowLayout, TextField,
} from 'react-admin'

const EnumsShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Détails">
        <TextField source="count_comments" label="Commentaires" />
        <TextField source="count_likes" label="Likes" />
        <TextField source="count_follows" label="Suivis" />
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default EnumsShow
