import { createMuiTheme } from '@material-ui/core/styles'
import { green } from '@material-ui/core/colors'

const CmjTheme = createMuiTheme({
  palette: {
    primary: {
      main: green[500],
    },
    secondary: {
      main: green[400],
    },
  },
  typography: {
    // Use the system font instead of the default Roboto font.
    fontFamily: ['-apple-system', 'BlinkMacSystemFont', '"Segoe UI"', 'Arial', 'sans-serif'].join(','),
  },
})

export default CmjTheme
