import React from 'react'
import {
  BooleanField, Datagrid, List, NumberField, TextField, DeleteButton,
} from 'react-admin'

const MenuParametersList = (props) => (
  <List {...props} perPage={25} sort={{ field: 'priority', order: 'ASC' }}>
    <Datagrid rowClick="show">
      <TextField source="title" label="Titre" />
      <TextField source="link" label="Lien URL" />
      <NumberField source="priority" label="Priorité" />
      <BooleanField source="webview" label="Webview ?" />
      <DeleteButton />
    </Datagrid>
  </List>
)

export default MenuParametersList
