import React, { useState } from 'react'
import { Button, SimpleForm } from 'react-admin'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import FormControl from '@material-ui/core/FormControl'
import { createForm } from 'final-form'
import dataProvider, { ACTIONS, CONTENT_TYPES } from '../providers/http/provider'

const ExportModalView = (props) => {
  const [showDialog, setShowDialog] = useState(false)
  const [dateFrom, setDateFrom] = useState(null)
  const [dateTo, setDateTo] = useState(null)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleCashSubmitClick = () => {
    let query = `${props.path}/export?get=csv`
    if (dateFrom) {
      const formattedDate = formatterDate(dateFrom)
      query += `&start=${formattedDate}`
    }
    if (dateTo) {
      const formattedDate = formatterDate(dateTo)
      query += `&end=${formattedDate}`
    }
    dataProvider(ACTIONS.GET_BINARY, query, CONTENT_TYPES.RAW_BINARY)
      .then((result) => {
        setShowDialog(false)
      })
      .catch((e) => {})
  }

  const appendLeadingZeros = (n) => {
    if (n <= 9) {
      return `0${n}`
    }
    return n
  }

  const formatterDate = (str) => {
    const formattedDate = `${appendLeadingZeros(str.getDate())}-${appendLeadingZeros(
      str.getMonth() + 1
    )}-${str.getFullYear()}`
    return formattedDate.split('-').reverse().join('-')
  }

  const handleChangeDateFrom = (date) => {
    setDateFrom(date)
  }

  const handleChangeDateTo = (date) => {
    setDateTo(date)
  }

  const csvExportDialogView = (label) => (
    <Dialog fullWidth open={showDialog}>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <SimpleForm
          form={createForm({
            onSubmit: () => {},
          })}
          toolbar={null}
        >
          <FormControl>
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                id="start"
                label="De"
                value={dateFrom}
                onChange={handleChangeDateFrom}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
              <KeyboardDatePicker
                disableToolbar
                variant="inline"
                format="dd-MM-yyyy"
                margin="normal"
                id="end"
                label="A"
                value={dateTo}
                onChange={handleChangeDateTo}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </FormControl>
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCashSubmitClick} label="Télécharger">
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler">
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = props.name === undefined ? 'Export csv' : props.name
    return (
      <>
        <Button onClick={handleClick} label={label} />
        {csvExportDialogView(label)}
      </>
    )
  }

  return result()
}

export default ExportModalView
