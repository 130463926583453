import React, { useEffect, useState } from 'react'
import { Button } from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import dataProvider from '../../helpers/dataProvider'

const CashOutModalView = ({ record, type }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)

  useEffect(() => {}, [])

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const needToShowButton = () => record.moneyOutEnabled

  const handleCashIn = () => {
    setLoading(true)
    dataProvider
      .post(`/cashout?purchaseMaturityId=${record.id}`, {})
      .then(setShowDialog(false))
      .finally(() => setLoading(false))
  }

  const cashOutDialogView = (label) => (
    <Dialog fullWidth open={showDialog}>
      <DialogTitle>Reverser le paiement du professionel</DialogTitle>
      <DialogActions>
        <Button onClick={handleCashIn} label="Confirmer" disabled={loading}>
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler" disabled={loading}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = 'Reverser'
    return (
      <>
        <Button onClick={handleClick} label={label} disabled={!needToShowButton()}>
          <AttachMoneyIcon />
        </Button>
        {cashOutDialogView(label)}
      </>
    )
  }

  return result()
}

export default CashOutModalView
