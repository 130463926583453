import React from 'react'
import {
  ArrayField,
  Datagrid,
  DateField,
  FunctionField,
  NumberField,
  ReferenceField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  TextField,
  useRefresh,
} from 'react-admin'
import AddPaymentReminderModalView from './components/AddPaymentReminderModalView'
import { paymentReminderModesLabels, paymentReminderTypesLabels } from '../../enums/PaymentReminders'

const PurchaseMaturityShow = (props) => {
  const refresh = useRefresh()
  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Informations">
          <SimpleShowLayout>
            <TextField source="id" />
            <ReferenceField label="Devis" link="show" source="purchase" reference="purchases">
              <TextField source="status" />
            </ReferenceField>
            <DateField locales="fr-FR" source="maturityDueDate" label="Date d'échéance" />
            <TextField source="businessLineType" label="Business Line" />
            <ReferenceField label="Acheteur" link="show" source="customerId" reference="customers">
              <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
            </ReferenceField>
            <ReferenceField label="Vendeur" link="show" source="proId" reference="professionals">
              <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
            </ReferenceField>
            <NumberField source="quoteCustomerTTC" label="Devis TTC" options={{ style: 'currency', currency: 'EUR' }} />
            <NumberField
              source="paymentCustomerTTC"
              label="Paiment Client"
              options={{ style: 'currency', currency: 'EUR' }}
            />
            <TextField source="customerPaymentType" label="Paiement Client" />
            <TextField source="customerDepositStatus" label="Statut Avance Client" />
            <TextField source="customerPaymentStatus" label="Statut Paiement Client" />
          </SimpleShowLayout>
        </Tab>
        <Tab label="Relances de paiement">
          <AddPaymentReminderModalView onPaymentReminderAdded={() => refresh()} />
          <ArrayField source="paymentReminders">
            <Datagrid>
              <FunctionField label="Type de relance" render={(record) => paymentReminderTypesLabels[record.type]} />
              <FunctionField label="Mode de relance" render={(record) => paymentReminderModesLabels[record.mode]} />
              <DateField locales="fr-FR" source="date" label="Date de la relance" />
              <DateField
                locales="fr-FR"
                source="nextPaymentReminderEstimatedDate"
                label="Date de la prochaine relance envisagée"
              />
            </Datagrid>
          </ArrayField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default PurchaseMaturityShow
