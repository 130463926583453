import * as React from 'react'
import {
  Button, useNotify, useRefresh, useUnselectAll,
} from 'react-admin'
import { Cached } from '@material-ui/icons'
import dataProvider from '../../helpers/dataProvider'
import { IDENTIFIERS } from '../../config/resources'

const ReloadStatusButton = (props) => {
  const { selectedIds } = props
  const refresh = useRefresh()
  const [loading, setLoading] = React.useState(false)
  const notify = useNotify()
  const unselectAll = useUnselectAll()
  const reloadStatus = () => {
    setLoading(true)
    dataProvider
      .post('/avance-immediate/transactions/reload-status', selectedIds)
      .then(() => {
        setLoading(false)
        refresh()
        notify('Les status des demandes sélectionnées ont été mis à jour', 'info')
        unselectAll(IDENTIFIERS.AVANCE_IMMEDIATE_TRANSACTIONS)
      })
      .catch(() => {
        setLoading(false)
        notify("Erreur: les statuts n'ont pas pu être mis à jour", 'warning')
      })
  }

  return (
    <Button label="Recharger les statuts" disabled={loading} onClick={reloadStatus}>
      <Cached />
    </Button>
  )
}

export default ReloadStatusButton
