import React from 'react'
import {
  Datagrid,
  Filter,
  List,
  TextField,
  TextInput,
  EditButton,
  TopToolbar,
  DateField,
  ReferenceField,
} from 'react-admin'
import ExportModalView from '../../../export/ExportModalView'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../../config/resources'

const CustomerFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nom" source="qName" alwaysOn />
    <TextInput label="Téléphone" source="qPhone" alwaysOn />
    <TextInput label="Ville" source="qCity" alwaysOn />
    <TextInput label="Code postal" source="qZipCode" alwaysOn />
  </Filter>
)

const ExportActions = () => (
  <TopToolbar>
    <ExportModalView path="offre1bis/customers" />
  </TopToolbar>
)

const Offre1bisCustomersList = (props) => (
  <List
    {...props}
    actions={<ExportActions />}
    sort={{ field: 'createdDate', order: 'DESC' }}
    filters={<CustomerFilter />}
  >
    <Datagrid rowClick="show">
      <TextField source="phone_number" label="Téléphone" />
      <DateField locales="fr-FR" source="createdDate" label="Date de création" />
      <TextField source="last_name" label="Nom" />
      <TextField source="first_name" label="Prénom" />
      <TextField source="email_address" label="Email" />
      <ReferenceField
        label="Vendeur"
        link="show"
        source="professionalProfileEntityRelationId"
        reference={RESOURCE_IDENTIFIERS.PROFESSIONAL}
      >
        <TextField source="profile.fullname" />
      </ReferenceField>
      <TextField source="zip_code" label="CP" />
      <TextField source="city" label="Ville" />
      <EditButton />
    </Datagrid>
  </List>
)

export default Offre1bisCustomersList
