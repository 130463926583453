import React from 'react'
import {
  BooleanInput,
  Datagrid,
  DateField,
  Filter,
  FunctionField,
  List,
  ReferenceField,
  TextField,
  TextInput,
} from 'react-admin'
import ReloadStatusButton from './ReloadStatusButton'
import { IDENTIFIERS } from '../../config/resources'
import CashInAvanceImmediateModalView from './components/CashInAvanceImmediateModalView'
import PaymentRequestAvanceImmediateModalView from './components/PaymentRequestAvanceImmediateModalView'
import BankTransactionsFileImportModalView from './components/BankTransactionsFileImportModalView'

const AvanceImmediateListBulkActionButtons = (props) => (
  <>
    <ReloadStatusButton label="Recharger les status" {...props} />
  </>
)

const AvanceImmediateTransactionsFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Numéro facture client" source="qInvoiceNumber" alwaysOn />
    <BooleanInput label="Demandes de paiement en attente" source="qNotSent" alwaysOn />
  </Filter>
)

const AvanceImmediateTransactionsList = (props) => (
  <List
    {...props}
    sort={{ field: 'createdDate', order: 'DESC' }}
    bulkActionButtons={<AvanceImmediateListBulkActionButtons />}
    actions={<BankTransactionsFileImportModalView />}
    filters={<AvanceImmediateTransactionsFilter />}
  >
    <Datagrid>
      <DateField locales="fr-FR" source="createdDate" label="Date de création" />
      <FunctionField
        label="Identifiant URSSAF de la demande"
        render={(record) => {
          if (record.status === 'INVALIDEE_PAR_ADMIN_INTERNE') {
            return <></>
          }
          if (record.idDemandePaiement) {
            return <TextField record={record} source="idDemandePaiement" />
          }
          return <PaymentRequestAvanceImmediateModalView record={record} />
        }}
      />
      <TextField source="status" label="Statut URSSAF de la demande" />
      <DateField locales="fr-FR" source="finalPaymentDate" label="Date du virement" />
      <ReferenceField
        label="Numéro de facture 1bis"
        link="show"
        source="idInvoice"
        reference={IDENTIFIERS.INVOICES_1_BIS}
      >
        <TextField source="number" />
      </ReferenceField>
      <ReferenceField label="Numéro de facture" link={false} source="idOrder" reference={IDENTIFIERS.ORDER}>
        <FunctionField
          render={(record) => (
            <a href={`/#/${IDENTIFIERS.PURCHASE_REQUEST}/${record.idPurchaseRequest}/show`}>{record.invoiceNumber}</a>
          )}
        />
      </ReferenceField>
      <ReferenceField label="Client app" link="show" source="customerEntityRelationId" reference="customers">
        <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
      </ReferenceField>
      <ReferenceField label="Client 1 bis" link="show" source="customer1bisId" reference="customers1bis">
        <FunctionField render={(record) => `${record.last_name} ${record.first_name}`} />
      </ReferenceField>
      <ReferenceField label="Professionnel" link="show" source="sellerEntityRelationId" reference="professionals">
        <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
      </ReferenceField>
      <CashInAvanceImmediateModalView />
    </Datagrid>
  </List>
)

export default AvanceImmediateTransactionsList
