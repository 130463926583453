import React, { useState } from 'react'
import {
  Button, FileField, FileInput, SimpleForm,
} from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import { ImportExport } from '@material-ui/icons'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import dataProvider from '../../../helpers/dataProvider'

const BankTransactionsFileImportModalView = (props) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleOnSubmit = (e) => {
    const formData = new FormData()
    formData.append('file', e.csvFile.rawFile)
    dataProvider
      .post('/avance-immediate/transactions/cashIn/batch/file', formData, 'multipart/form-data')
      .then((response) => {
        if (response.status === 200) {
          window.alert('Les transactions ont bien été importées.')
        } else {
          window.alert(
            "Une erreur est survenue lors de l'envoi des informations. Veillez à bien respecter les consignes d'import."
          )
        }
        window.location.reload()
      })
      .catch(() => {
        window.alert("Une erreur est survenue lors de l'envoi des informations.")
        window.location.reload()
      })
  }

  const hideDialog = () => {
    setShowDialog(false)
  }

  const handleClick = () => {
    setShowDialog(true)
  }

  return (
    <>
      <Button onClick={handleClick} label="Importer fichier de transactions">
        <ImportExport />
      </Button>
      <Dialog fullWidth open={showDialog}>
        <DialogTitle>Import du fichier de transactions</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Importez le fichier CSV contenant un export de transactions bancaires d&apos;un compte Banque Populaire.
            Veillez à choisir les bonnes options d&apos;export:
            <ul>
              <li>Format de fichier: CSV</li>
              <li>Séparateur: ; (point-virgule)</li>
              <li>Marqueur de décimales: . (point)</li>
            </ul>
          </DialogContentText>
          <SimpleForm save={handleOnSubmit}>
            <FileInput source="csvFile" label="Fichier à importer" accept=".csv">
              <FileField source="csvUrl" title="title" />
            </FileInput>
          </SimpleForm>
        </DialogContent>
        <DialogActions>
          <Button onClick={hideDialog} label="Annuler">
            <IconCancel />
          </Button>
        </DialogActions>
      </Dialog>
    </>
  )
}

export default BankTransactionsFileImportModalView
