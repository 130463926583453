import get from 'lodash/get'
import React from 'react'

import Chip from '@material-ui/core/Chip'
import { makeStyles } from '@material-ui/core/styles'

import { marketSegmentations } from '../../pages/professionals/shared/professionalQualificationsChoices'
import multipleChipsDataTypeEnum from './multipleChipsDataType'
import FieldTitleShow from '../FieldTitleShow'

const useStyles = makeStyles(() => ({
  multipleChipsTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    marginTop: '10px',
  },
  multipleChipsContainer: {
    padding: '8px 0 4px',
  },
  chip: {
    margin: '4px',
  },
}))

const getMarketSegmentationTranslation = (untranslatedSegment) => {
  const marketSegmentation = marketSegmentations.find((segment) => segment.id === untranslatedSegment)
  return marketSegmentation?.name ?? untranslatedSegment
}

const MultipleChips = ({
  record, source, title, dataType,
}) => {
  const classes = useStyles()
  const chipsArray = get(record, source)
  return (
    <div>
      {title && <FieldTitleShow title={title} />}
      {!chipsArray || chipsArray.length === 0 ? null : (
        <div className={classes.multipleChipsContainer}>
          {chipsArray.map((item) => (
            <Chip
              label={
                dataType === multipleChipsDataTypeEnum.MARKETSEGMENTATION
                  ? getMarketSegmentationTranslation(item)
                  : item
              }
              key={item}
              className={classes.chip}
            />
          ))}
        </div>
      )}
    </div>
  )
}

export default MultipleChips
