import React from 'react'
import {
  ArrayInput,
  AutocompleteInput,
  BooleanInput,
  DateInput,
  Edit,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleForm,
  SimpleFormIterator,
  TextInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const CustomerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <ReferenceInput
        label="État"
        source="customer.status"
        reference="enums"
        key="status"
        filter={{ enum: 'accountStatus' }}
      >
        <SelectInput optionText="name" />
      </ReferenceInput>
      <TextInput source="profile.lastName" label="Nom" />
      <TextInput source="profile.firstName" label="Prénom" />
      <TextInput source="account.email" label="Adresse mail" />

      <TextInput source="customer.address.streetAndNumber" label="N° et rue" />
      <TextInput source="customer.address.zipCode" label="Code postal" />
      <TextInput source="customer.address.city" label="Ville" />

      <TextInput source="profile.country" label="Pays" />

      <TextInput source="profile.nationality" label="Nationalité" />
      <DateInput source="profile.birthdate" label="Date de Naissance" />
      <ReferenceInput
        label="Parrain"
        source="relatedProfessional"
        reference={RESOURCE_IDENTIFIERS.PROFESSIONAL}
        filterToQuery={(searchText) => ({ qName: searchText })}
        allowEmpty
      >
        <AutocompleteInput optionText="profile.fullname" />
      </ReferenceInput>
      <ReferenceInput
        label="Code avantage"
        source="customer.appliedCouponCodeId"
        reference={RESOURCE_IDENTIFIERS.COUPON_CODES}
        filterToQuery={(searchText) => ({ qName: searchText, qDisabled: false })}
        allowEmpty
      >
        <AutocompleteInput optionText="name" />
      </ReferenceInput>
      <NumberInput source="customer.gardenSurface" label="Surface du jardin (m²)" />
      <TextInput source="profile.phone" label="Téléphone" />
      <BooleanInput source="profile.subscribedNewsletter" label="Newsletter" />
      <BooleanInput source="profile.subscribedNotification" label="Notifications mail" />
      <BooleanInput source="profile.subscribedSms" label="Notifications SMS" />
      <BooleanInput source="profile.shouldSendLetter" label="Envoi mails facture/devis par lettre" />
      <ArrayInput source="entityRelation.fees" label="Frais de service">
        <SimpleFormIterator disableRemove disableAdd>
          <TextInput source="productFamilyId" label="Code" disabled />
          <NumberInput source="value" label="Valeur" step={1} />
        </SimpleFormIterator>
      </ArrayInput>
      <ImageInput source="pictureFile" label="Photo de profil" accept="image/*">
        <ImageField source="profile.pictureFile" />
      </ImageInput>

      <RichTextInput source="customer.comment" label="Commentaires" toolbar={[['bold', 'italic', 'underline']]} />
    </SimpleForm>
  </Edit>
)

export default CustomerEdit
