import React, { useState } from 'react'
import {
  ArrayField,
  BooleanField,
  Button,
  ChipField,
  Datagrid,
  DateField,
  EmailField,
  FileField,
  FunctionField,
  ImageField,
  NumberField,
  Pagination,
  ReferenceField,
  ReferenceManyField,
  RichTextField,
  Show,
  ShowButton,
  SingleFieldList,
  Tab,
  TabbedShowLayout,
  TextField,
  UrlField,
} from 'react-admin'
import MailOutlineIcon from '@material-ui/icons/MailOutline'
import CloudDownloadIcon from '@material-ui/icons/CloudDownload'

import { dateAndTimeWithWeekday } from '../../helpers/dateRender'
import dataProvider, { ACTIONS, CONTENT_TYPES } from '../../providers/http/provider'
import dataProviderHelper from '../../helpers/dataProvider'
import multipleChipsDataTypeEnum from '../../components/multipleChips/multipleChipsDataType'
import MultipleChips from '../../components/multipleChips/MutlipleChips'
import CashOutModalView from './CashOutModalView'

const ProfessionalShow = (props) => {
  const [resetPasswordDate, setResetPasswordDate] = useState()
  const [passwordReset, setPasswordReset] = useState(false)
  const proId = props.id

  const handleResetPassword = () => {
    dataProviderHelper.post(`/professionals/${props.id}/reset-password`).then((response) => {
      if (response.status === 200) {
        setResetPasswordDate(new Date())
        setPasswordReset(true)
      }
    })
  }

  const resetButton = () => {
    if (!passwordReset) {
      return (
        <Button onClick={handleResetPassword} label="Envoyer le mail de nouveau mot de passe">
          <MailOutlineIcon />
        </Button>
      )
    }
    return (
      <>
        {resetPasswordDate.toLocaleDateString()} MDP envoyé à {resetPasswordDate.toLocaleTimeString()}
      </>
    )
  }

  const handleDownloadCsvBilling = () => {
    dataProvider(ACTIONS.GET_BINARY, `professionals/${props.id}/billing_csv`, CONTENT_TYPES.RAW_BINARY)
      .then((result) => {})
      .catch((e) => {})
  }

  const downloadCsvBillingButton = () => (
    <Button onClick={handleDownloadCsvBilling} label="Historique facturation csv">
      <CloudDownloadIcon />
    </Button>
  )

  return (
    <Show {...props}>
      <TabbedShowLayout>
        <Tab label="Détails">
          {resetButton()}
          {downloadCsvBillingButton()}
          <TextField source="id" label="Identifiant" />
          <TextField source="entityRelation.sageId" label="SAGE ID" />
          <TextField source="professional.status" label="État" />
          <ArrayField source="professional.banReasonsToDisplay" label="Banni pour">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <MultipleChips
            source="professional.marketSegmentations"
            title="Segmentation de marché"
            dataType={multipleChipsDataTypeEnum.MARKETSEGMENTATION}
          />
          <BooleanField source="professional.displayInSearchResults" label="Apparait dans les résultats de recherche" />
          <TextField source="professional.billingProfile" label="Type" />

          <EmailField source="account.email" label="Email" />
          <TextField source="profile.firstName" label="Prénom" />
          <TextField source="profile.lastName" label="Nom" />
          <TextField source="professional.address.streetAndNumber" label="N° et rue" />
          <TextField source="professional.address.city" label="Ville" />
          <TextField source="professional.address.zipCode" label="Code postal" />

          <TextField source="professional.address.region" label="Région" />
          <TextField source="professional.address.commercialArea" label="Zone commerciale" />

          <TextField source="profile.nationality" label="Nationalité" />
          <TextField source="profile.phone" label="Téléphone" />
          <DateField locales="fr-FR" options={dateAndTimeWithWeekday} source="createdDate" label="Date de création" />
          <DateField locales="fr-FR" source="profile.birthdate" label="Date de naissance" />
          <ImageField source="profile.pictureFile" label="Photo de profil" />

          <RichTextField source="professional.description" label="Description de l'activité" />

          <BooleanField source="profile.subscribedNewsletter" label="Newsletter" />
          <BooleanField source="profile.subscribedSms" label="SMS" />
          <BooleanField source="profile.subscribedNotification" label="Notifications push" />
          <NumberField source="profile.notificationTokensCount" label="Devices push enregistrés" />
          <BooleanField source="entityRelation.professionalIsTrusted" label="Confiance 100%" />
          <BooleanField source="entityRelation.professionalIsReactive" label="Réactif" />
          <RichTextField source="professional.comment" label="Commentaires" />
          <ArrayField source="professional.followUpEmailsSentToDisplay" label="Emails de relance">
            <Datagrid>
              <TextField label="" source="emailTypeName" />
              <DateField locales="fr-FR" label="Envoyé le" source="sendingDate" />
            </Datagrid>
          </ArrayField>
          <DateField locales="fr-FR" source="professional.lastCGUDateValidation" label="Dernière acceptation CGU" />

          <NumberField source="entityRelation.professionalReceivedRequests" label="Demandes reçues" />
          <NumberField source="entityRelation.professionalAcceptedRequests" label="Demandes acceptées" />
          <NumberField source="entityRelation.professionalSentPurchases" label="Devis envoyés" />
          <NumberField source="entityRelation.professionalAcceptedPurchases" label="Devis acceptés" />
          <TextField source="entityRelation.professionalExternalId" label="Identifiant client" />
          <TextField source="referralCode" label="Referral code" />
          <TextField source="serviceLevel" label="Offre" />
          <ArrayField source="professional.proposedServicesToDisplay" label="Services proposés">
            <SingleFieldList>
              <ChipField source="name" />
            </SingleFieldList>
          </ArrayField>
          <TextField source="acceptedQuotesOnSentQuotes" label="Devis acceptés / Devis envoyés" />
          <TextField source="acceptedQuotesOnReceivedRequests" label="Devis acceptés / Demandes reçues" />
          <TextField source="sentQuotesOnAcceptedRequests" label="Devis envoyés / Demandes acceptées" />
          <ArrayField source="entityRelation.fees" label="Commission">
            <Datagrid>
              <TextField source="productFamilyId" label="Code" />
              <NumberField source="value" label="Valeur" />
            </Datagrid>
          </ArrayField>
          <ArrayField source="realisationsImages" label="Réalisations">
            <SingleFieldList>
              <ImageField source="path" />
            </SingleFieldList>
          </ArrayField>
        </Tab>

        <Tab label="Société">
          <TextField source="professional.billingProfile" label="Type" />

          <TextField source="professional.company.name" label="Nom de la société" />
          <NumberField source="professional.company.registrationNumber" label="Siret" />
          <NumberField source="professional.company.vatNumber" label="N° TVA" />
          <TextField source="professional.company.professionalSeniority" label="Nombre d'années d'expérience" />
          <TextField source="professional.company.capital" label="Capital" />
          <TextField source="professional.company.legalStatus" label="Forme juridique" />
          <NumberField source="professional.staff.nbEmployees" label="Nombre de salariés" />
          <NumberField source="professional.staff.nbAprentices" label="Nombres d'apprentis" />
          <NumberField source="professional.staff.nbInterns" label="Nombre de stagiaires" />
          <NumberField source="professional.revenues.totalRevenue" label="CA total estimé" />
          <NumberField source="professional.revenues.maintenancePercentage" label="% entretien" />
          <NumberField source="professional.revenues.maintenanceRevenue" label="CA entrerien" />
          <NumberField source="professional.revenues.creationPercentage" label="% création" />
          <NumberField source="professional.revenues.creationRevenue" label="CA création" />
          <TextField source="professional.cooperative" label="Coopérative" />
          <DateField locales="fr-FR" source="professional.lastContactDate" label="Date du dernier contact" />
          <DateField locales="fr-FR" source="professional.nextContactDate" label="Prochain contact prévu le" />
          <TextField source="professional.insurance.name" label="Nom de la compagnie d'assurance" />
          <TextField source="professional.insurance.number" label="Numéro de l'assurance" />
          <NumberField source="professional.hourlyPricing.priceMin" label="Tarif horaire minimum" />
          <NumberField source="professional.hourlyPricing.priceMax" label="Tarif horaire maximum" />
          <ArrayField source="entityRelation.professionalZipCodes" label="Zones d'activité">
            <SingleFieldList>
              <ChipField source="zipCode" />
            </SingleFieldList>
          </ArrayField>
          <BooleanField
            source="entityRelation.professionalZipCodesDeactivatableWithInactivity"
            label="Zones d'activité désactivable en cas de visite sans devis"
          />
          <TextField source="professional.company.agreementNumber" label="Numéro de la déclaration SAP" />
          <FileField
            source="professional.company.agreementFile"
            label="Document SAP"
            title="Télécharger"
            target="_blank"
          />
        </Tab>

        <Tab label="Wallet">
          <ReferenceField label="Identifiant" link="show" source="wallet.id" reference="wallets">
            <TextField source="idProviderReference" label="Identifiant ATJ (id externe)" />
          </ReferenceField>
          <TextField source="wallet.idProviderTechnical" label="Identifiant Lemonway" />
          <UrlField source="wallet.lemonwayUrl" label="Lien lemonway" target="_blank" />
          <TextField source="wallet.status" label="Statut" />
          <NumberField
            source="wallet.balance"
            label="Solde"
            options={{
              style: 'currency',
              currency: 'EUR',
            }}
          />
          <BooleanField source="wallet.active" label="Actif" />
          <ArrayField source="purchaseMaturityInError" label="Paiments en erreur">
            <Datagrid>
              <ReferenceField label="Demande" link="show" source="purchaseRequest" reference="purchaserequests">
                <TextField source="id" label="Devis" />
              </ReferenceField>
              <ReferenceField label="Devis" link="show" source="purchase" reference="purchases">
                <TextField source="id" label="Devis" />
              </ReferenceField>
              <TextField source="number" title="# échance" label="# échance" />
              <DateField locales="fr-FR" source="dueDate" label="Date d'échéance" />
              <CashOutModalView />
            </Datagrid>
          </ArrayField>
        </Tab>

        <Tab label="Historique des réponses">
          <ReferenceManyField
            reference="purchaserequests"
            sort={{
              field: 'createdDate',
              order: 'DESC',
            }}
            target="professionalEntityRelation"
            addLabel={false}
            pagination={<Pagination />}
          >
            <Datagrid rowClick="show">
              <ReferenceField label="Demande" link="show" source="id" reference="purchaserequests">
                <TextField source="id" />
              </ReferenceField>
              <TextField source="businessLine" label="Prestation" />
              <ReferenceField label="Client" link="show" source="customerEntityRelationId" reference="customers">
                <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
              </ReferenceField>
              <BooleanField source="visitRequired" label="Avec visite" />
              <TextField source="status" label="État Demande" />
              <FunctionField
                label="État Réponse"
                render={(record) => record.purchaseRequestsProfessional.filter((req) => req.professionalEntityRelation === proId)[0]
                  ?.status}
              />
              <TextField source="origin" label="Origine" />
              <DateField locales="fr-FR" source="createdDate" label="Date de création" />
              <DateField locales="fr-FR" source="nextAppointment" label="Prochain RDV" />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Historique des devis">
          <ReferenceManyField
            reference="purchases"
            sort={{
              field: 'createdDate',
              order: 'DESC',
            }}
            addLabel={false}
            pagination={<Pagination />}
            target="professionalEntityRelation"
          >
            <Datagrid>
              <TextField source="id" />
              <ReferenceField label="Demande" link="show" source="purchaseRequest" reference="purchaserequests">
                <TextField source="id" />
              </ReferenceField>
              <DateField locales="fr-FR" source="createdDate" label="Date de création" />
              <ReferenceField label="Client" link="show" source="buyerEntityRelation" reference="customers">
                <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
              </ReferenceField>
              <TextField source="status" label="État" />
              <TextField source="businessLine.businessLineType" label="Business Line" />
              <FileField source="quote.path" title="Devis" target="_blank" label="Devis" />
              <NumberField
                source="unitPrice"
                label="Prix app"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
              />
              <NumberField
                source="priceTTC"
                label="Prix TTC"
                options={{
                  style: 'currency',
                  currency: 'EUR',
                }}
              />
              <ShowButton />
            </Datagrid>
          </ReferenceManyField>
        </Tab>

        <Tab label="Emails/SMS">
          <ReferenceManyField
            reference="notifications"
            sort={{
              field: 'createdDate',
              order: 'DESC',
            }}
            addLabel={false}
            target="entity"
            pagination={<Pagination />}
          >
            <Datagrid>
              <TextField source="provider" label="Type" />
              <TextField source="status" label="Statut" />
              <DateField locales="fr-FR" showTime source="createdDate" label="Date de création" />
              <TextField source="type" label="Catégorie" />
              <TextField source="subject" label="Objet" />
              <RichTextField source="content" />
              <ArrayField source="attachments" label="PJ">
                <Datagrid>
                  <FileField source="path" target="_blank" title="filename" label="PJ" />
                </Datagrid>
              </ArrayField>
            </Datagrid>
          </ReferenceManyField>
        </Tab>
      </TabbedShowLayout>
    </Show>
  )
}

export default ProfessionalShow
