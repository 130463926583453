import React, { useState } from 'react'
import {
  List, Datagrid, TextField as Field, FunctionField,
} from 'react-admin'
import Button from '@material-ui/core/Button'
import { Modal } from '@material-ui/core'
import Backdrop from '@material-ui/core/Backdrop'
import Fade from '@material-ui/core/Fade'
import { makeStyles } from '@material-ui/core/styles'
import TextField from '@material-ui/core/TextField'
import dataProvider from '../../helpers/dataProvider'

const useStyles = makeStyles((theme) => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    border: '2px solid #000',
    boxShadow: theme.shadows[5],
    padding: theme.spacing(2, 4, 3),
  },
}))

const Parameters = (props) => {
  const classes = useStyles()
  const [rowValueIsUpdating, setRowValueIsUpdating] = useState(new Map())

  const handleUpdateOnclick = (id) => {
    const newMap = new Map(rowValueIsUpdating)
    newMap.set(id, true)
    setRowValueIsUpdating(newMap)
  }

  const handleCloseModal = (id) => {
    debugger
    const newMap = new Map(rowValueIsUpdating)
    newMap.set(id, false)
    setRowValueIsUpdating(newMap)
  }

  const handleChangeValue = (event, record) => {
    record.newValue = event.target.value
  }

  const handleSaveChanges = (record) => {
    dataProvider
      .put('/parameters', {
        id: record.id,
        newValue: record.newValue,
      })
      .then((result) => {
        if (result.status === 200) {
          record.value = Number(record.newValue)
          record.newValue = undefined
          setRowValueIsUpdating(new Map())
        }
      })
  }

  return (
    <>
      <List {...props}>
        <Datagrid>
          <Field source="id" label="ID" />
          <Field source="value" label="Value" />
          <FunctionField
            label=""
            render={(record) => (
              <Button
                variant="contained"
                color="green"
                onClick={(event) => {
                  handleUpdateOnclick(record.id)
                }}
              >
                Update
              </Button>
            )}
          />
          <FunctionField
            render={(record) => (
              <div>
                <Modal
                  aria-labelledby="transition-modal-title"
                  aria-describedby="transition-modal-description"
                  className={classes.modal}
                  open={rowValueIsUpdating.get(record.id)}
                  onClose={(event) => handleCloseModal(record.id)}
                  closeAfterTransition
                  BackdropComponent={Backdrop}
                  BackdropProps={{
                    timeout: 500,
                  }}
                >
                  <Fade in={rowValueIsUpdating.get(record.id)}>
                    <div className={classes.paper}>
                      <TextField
                        defaultValue={record.id}
                        label="ID"
                        InputProps={{
                          readOnly: true,
                        }}
                        id="id"
                      />
                      <br />
                      <TextField
                        defaultValue={record.value}
                        label="value"
                        id="value"
                        onChange={(event) => handleChangeValue(event, record)}
                      />
                      <br />
                      <Button variant="contained" color="green" onClick={(event) => handleSaveChanges(record)}>
                        Save changes
                      </Button>
                    </div>
                  </Fade>
                </Modal>
              </div>
            )}
          />
        </Datagrid>
      </List>
      <h5 style={{ textAlign: 'center' }}>Les dates doivent être renseignées au format AAAA-MM-JJ</h5>
    </>
  )
}
export default Parameters
