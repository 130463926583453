import axios from 'axios'
// Import { constants as httpStatuses } from 'http2'

const httpClient = (params = {}) => {
  const options = params
  if (!params.headers) {
    options.headers = { Accept: 'application/json' }
  }
  options.headers.Authorization = `Bearer ${localStorage.getItem('accessToken')}`
  return axios(options).catch((err) => {
    if (err.response.status === 401) {
      localStorage.setItem('needsRefresh', 'true')
      throw Error('Actualisation...')
    }
    throw Error(`${err.response?.statusText || 'Une erreur est survenue'} (${err.response?.status})`)
  })
}

export default httpClient
