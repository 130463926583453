import React, { useState } from 'react'
import { Button, SimpleForm } from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import SendIcon from '@material-ui/icons/Send'
import FormControl from '@material-ui/core/FormControl'
import { createForm } from 'final-form'
import TextField from '@material-ui/core/TextField'
import DialogContentText from '@material-ui/core/DialogContentText'
import dataProvider from '../../helpers/dataProvider'

const PushNotifModalView = () => {
  const [showDialog, setShowDialog] = useState(false)
  const [title, setTitle] = useState('')
  const [content, setContent] = useState('')

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSend = () => {
    const body = {
      content,
      title,
    }
    if (content && title) {
      return dataProvider.post('/professionals-activity/pushnotifications', body).then(setShowDialog(false))
    }
  }

  const changeContent = (event) => {
    setContent(event.target.value)
  }

  const changeTitle = (event) => {
    setTitle(event.target.value)
  }

  const pushNotifDialogView = (label) => (
    <Dialog fullWidth open={showDialog}>
      <DialogTitle>Envoyer une notification push à tous les jardiniers</DialogTitle>
      <DialogContentText>
        <b>&emsp;&emsp;Attention ! A l'envoi de ce formulaire tout les jardiniers recevront </b>
      </DialogContentText>
      <DialogContentText>
        <b>&emsp;&emsp;une notification push avec le contenu saisi ci-dessous!</b>
      </DialogContentText>
      <DialogContent>
        <SimpleForm
          form={createForm({
            onSubmit: () => {},
          })}
          toolbar={null}
        >
          <FormControl>
            <TextField
              label="Titre"
              required="true"
              onChange={(event) => changeTitle(event)}
              type="string"
              inputProps={{ maxLength: 200, textColor: 'red' }}
            />
            <TextField
              label="Texte"
              required="true"
              onChange={(event) => changeContent(event)}
              type="string"
              inputProps={{ maxLength: 1000, margin: 'normal' }}
            />
          </FormControl>
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSend} label="Envoyer">
          <SendIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler">
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = 'Push notif aux jardiniers'
    return (
      <>
        <Button onClick={handleClick} label={label}>
          <SendIcon />
        </Button>
        {pushNotifDialogView(label)}
      </>
    )
  }

  return result()
}

export default PushNotifModalView
