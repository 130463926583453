import React from 'react'

export const UserSnap = () => {
  window.onUsersnapCXLoad = (api) => {
    api.init()
  }
  const script = document.createElement('script')
  script.defer = 1
  script.src = 'https://widget.usersnap.com/load/339e04f8-610f-4924-8120-9fb6a5ba7624?onload=onUsersnapCXLoad'
  document.getElementsByTagName('head')[0].appendChild(script)

  return <></>
}

export default UserSnap
