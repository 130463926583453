import React from 'react'
import { ReferenceManyField } from 'react-admin'

export const ReferenceManyFieldNoLabel = (props) => <ReferenceManyField {...props} />

ReferenceManyFieldNoLabel.defaultProps = {
  addLabel: false,
}

export function OrderTypeTranslated({ record }) {
  switch (record.type) {
    case 'OPERATOR_TO_SELLER':
      return <span>Commission</span>
    case 'OPERATOR_TO_BUYER':
      return <span>Facture client</span>
    case 'SELLER_TO_BUYER':
      return <span>SELLER_TO_BUYER</span>
    case 'SELLER_TO_OPERATOR':
      return <span>Vente Pro</span>
    default:
      return <span>Autre</span>
  }
}
