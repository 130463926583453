import React, { useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'

import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import DialogContentText from '@material-ui/core/DialogContentText'
import dataProvider from '../../../helpers/dataProvider'

const GenerateCreditNote1bisModalView = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const [loading, setLoading] = React.useState(false)
  const isCreditNoteRequested = record.creditNote && record.creditNote.status === 'CREATION_REQUESTED'
  const isInvoiceAlreadyPaid = record.customerPaymentStatus !== 'PAYMENT_ERROR' && record.customerPaymentStatus !== 'PAYMENT_WAITING'

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleConfirm = () => {
    const url = isCreditNoteRequested
      ? `/offre1bis/invoices/${record.id}/register-credit-note`
      : `/offre1bis/invoices/${record.id}/generate-credit-note`
    setLoading(true)
    dataProvider
      .post(url, {})
      .then(() => {
        setLoading(false)
        notify("L'avoir a bien été généré", 'info')
        refresh()
      })
      .catch(() => {
        notify("Erreur: l'avoir n'a pas pu être généré", 'info')
        setLoading(false)
      })
  }

  const needToShowButton = () => record.creditNote === undefined || record.creditNote.status === 'CREATION_REQUESTED'

  const generateCreditNoteDialogView = () => (
    <Dialog open={showDialog}>
      <DialogTitle>Générer un avoir pour cette facture ?</DialogTitle>
      <DialogContent>
        {isInvoiceAlreadyPaid ? (
          isCreditNoteRequested ? (
            <DialogContentText>
              <b>
                Cette facture est déjà payée. Souhaitez vous enregistrer qu'un avoir a été créé par le service comptable
                ?
              </b>
            </DialogContentText>
          ) : (
            <DialogContentText>
              <b>Cette facture est déjà payée. Souhaitez vous vraiment l'annuler par un avoir ?</b>
            </DialogContentText>
          )
        ) : (
          <DialogContentText>
            <b>Attention ! Une fois confirmée, un avoir pour le client et un avoir pour le pro seront générés.</b>
          </DialogContentText>
        )}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} label="Confirmer" disabled={loading}>
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler" disabled={loading}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    if (needToShowButton()) {
      const label = isInvoiceAlreadyPaid
        ? isCreditNoteRequested
          ? "Enregistrer l'avoir"
          : "Demander génération de l'avoir"
        : "Générer l'avoir"
      return (
        <>
          <Button onClick={handleClick} label={label} disabled={loading} style={{ padding: '16px' }}>
            <AttachMoneyIcon />
          </Button>
          {generateCreditNoteDialogView()}
        </>
      )
    }
    return <></>
  }

  return result()
}

export default GenerateCreditNote1bisModalView
