import React, { useEffect, useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'

import IconCancel from '@material-ui/icons/Cancel'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'
import { KeyboardDateTimePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import SendIcon from '@material-ui/icons/Send'
import { FormControlLabel, Switch } from '@material-ui/core'
import { CancelScheduleSend, RedoOutlined } from '@material-ui/icons'
import dataProvider from '../../../helpers/dataProvider'

const PaymentRequestAvanceImmediateModalView = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const [loading, setLoading] = React.useState(false)
  const [usesDeposit, setUsesDeposit] = React.useState(false)
  const [errorsLastAttempt, setErrorsLastAttempt] = useState([])
  const [formState, setFormState] = useState({
    idClientExternal: '',
    invoiceNumber: '',
    invoiceDate: null,
    jobStartDate: null,
    jobEndDate: null,
    depositValue: '',
    depositDate: null,
    finalPriceTTC: 0,
    finalPriceHT: 0,
    nbHours: 0,
    hourPrice: 0,
    complement2: '',
  })
  useEffect(() => {
    setLoading(true)
    dataProvider.get(`/avance-immediate/transactions/${record.id}`).then((response) => {
      setLoading(false)
      setFormState({
        ...response.data.lastErrorAttemptRequestBody,
        birthdayClient: new Date(response.data.lastErrorAttemptRequestBody.birthdayClient),
        invoiceDate: new Date(response.data.lastErrorAttemptRequestBody.invoiceDate),
        jobStartDate: new Date(response.data.lastErrorAttemptRequestBody.jobStartDate),
        jobEndDate: new Date(response.data.lastErrorAttemptRequestBody.jobEndDate),
        depositDate: response.data.lastErrorAttemptRequestBody.depositDate
          ? new Date(response.data.lastErrorAttemptRequestBody.depositDate)
          : undefined,
      })
      setUsesDeposit(
        response.data.lastErrorAttemptRequestBody.depositValue || response.data.lastErrorAttemptRequestBody.depositDate
      )
      setErrorsLastAttempt(response.data.lastErrorAttemptErrors)
    })
  }, [])

  const modifyFormState = (key, value) => {
    setFormState({
      ...formState,
      [key]: value,
    })
  }

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSend = () => {
    setLoading(true)
    dataProvider
      .post(`/avance-immediate/transactions/${record.id}/send-infos`, {
        ...formState,
        invoiceDate: formState.invoiceDate.toISOString(),
        jobStartDate: formState.jobStartDate.toISOString(),
        jobEndDate: formState.jobEndDate.toISOString(),
        depositValue: usesDeposit ? formState.depositValue : undefined,
        depositDate: usesDeposit ? formState.depositDate.toISOString() : undefined,
      })
      .then(() => {
        setLoading(false)
        notify("La transaction a bien été envoyée à l'URSSAF", 'info')
        refresh()
      })
      .catch(() => {
        notify("Erreur: l'URSSAF à renvoyé une erreur", 'info')
        setLoading(false)
      })
  }

  const handleInvalidate = () => {
    if (window.confirm('Êtes-vous sûr de vouloir invalider cette transaction ?')) {
      setLoading(true)
      dataProvider
        .post(`/avance-immediate/transactions/${record.id}/invalidate`)
        .then(() => {
          setLoading(false)
          notify('La transaction a bien été invalidée', 'info')
          refresh()
        })
        .catch(() => {
          notify('Erreur: une erreur est survenue', 'error')
          setLoading(false)
        })
    }
  }

  const paymentRequestDialogView = () => (
    <Dialog
      fullScreen
      open={showDialog}
      style={{
        paddingBottom: '4em',
      }}
    >
      <DialogTitle>Envoyer la demande de paiement vers l'URSSAF</DialogTitle>
      <DialogContent>
        {errorsLastAttempt && errorsLastAttempt.length > 0 && (
          <>
            <h4>Erreur(s) retournée(s) lors de la dernière tentative</h4>
            {errorsLastAttempt.map((error) => (
              <div key={error.message}>
                <div>
                  <b>Code: </b>
                  {error.code}
                </div>
                <div>
                  <b>Message: </b>
                  {error.message}
                </div>
                <div>
                  <b>Description: </b>
                  {error.description}
                </div>
              </div>
            ))}
          </>
        )}
        <h4 style={{ marginTop: '2em' }}>Information envoyées lors de la dernière tentative</h4>
        <TextField disabled fullWidth label="Numéro de facture" type="string" value={formState.invoiceNumber} />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy HH:mm"
            margin="normal"
            id="invoiceDate"
            label="Date de la facture"
            value={formState.invoiceDate}
            onChange={(e) => modifyFormState('invoiceDate', e)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
          />
        </MuiPickersUtilsProvider>
        <br />
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy HH:mm"
            margin="normal"
            id="jobStartDate"
            label="Date de début de la prestation"
            value={formState.jobStartDate}
            onChange={(e) => modifyFormState('jobStartDate', e)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            style={{
              marginRight: '4em',
            }}
          />
        </MuiPickersUtilsProvider>
        <MuiPickersUtilsProvider utils={DateFnsUtils}>
          <KeyboardDateTimePicker
            disableToolbar
            variant="inline"
            format="dd/MM/yyyy HH:mm"
            margin="normal"
            id="jobEndDate"
            label="Date de fin de la prestation"
            value={formState.jobEndDate}
            onChange={(e) => modifyFormState('jobEndDate', e)}
            KeyboardButtonProps={{
              'aria-label': 'change date',
            }}
            style={{
              marginRight: '4em',
            }}
          />
        </MuiPickersUtilsProvider>
        <br />
        <FormControlLabel
          control={(
            <Switch
              checked={usesDeposit}
              onChange={(e) => {
                setUsesDeposit(e.target.checked)
              }}
            />
          )}
          label="Utiliser un acompte"
        />
        {usesDeposit && (
          <>
            <TextField fullWidth label="Montant de l'acompte" type="number" value={formState.depositValue} />
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <KeyboardDateTimePicker
                disableToolbar
                variant="inline"
                format="dd/MM/yyyy HH:mm"
                margin="normal"
                id="depositDate"
                label="Date de paiement de l'acompte"
                value={formState.depositDate}
                onChange={(e) => modifyFormState('depositDate', e)}
                KeyboardButtonProps={{
                  'aria-label': 'change date',
                }}
              />
            </MuiPickersUtilsProvider>
          </>
        )}
        <TextField
          fullWidth
          label="Prix final TTC"
          type="number"
          value={formState.finalPriceTTC}
          onChange={(e) => modifyFormState('finalPriceTTC', e.target.value)}
        />
        <TextField
          fullWidth
          label="Prix final HT"
          type="number"
          value={formState.finalPriceHT}
          onChange={(e) => modifyFormState('finalPriceHT', e.target.value)}
        />
        <TextField
          fullWidth
          label="Nombre d'heures de travail"
          type="number"
          value={formState.nbHours}
          onChange={(e) => modifyFormState('nbHours', e.target.value)}
        />
        <TextField
          fullWidth
          label="Prix horaire"
          type="number"
          value={formState.hourPrice}
          onChange={(e) => modifyFormState('hourPrice', e.target.value)}
        />
        <TextField
          fullWidth
          label="Complement 2 (numéro SAP pour les agréés, numéro SIRET ATJ pour les non-agréés)"
          type="string"
          value={formState.complement2}
          onChange={(e) => modifyFormState('complement2', e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <div style={{ marginRight: '40px' }}>
          <Button onClick={handleInvalidate} label="Invalider" disabled={loading}>
            <CancelScheduleSend />
          </Button>
          <Button onClick={handleSend} label="Envoyer" disabled={loading}>
            <SendIcon />
          </Button>
        </div>
        <Button color="default" onClick={handleCloseClick} label="fermer">
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = 'Corriger la demande de paiement'
    return (
      <>
        <Button onClick={handleClick} label={label} disabled={loading}>
          <RedoOutlined />
        </Button>
        {paymentRequestDialogView(label)}
      </>
    )
  }

  return result()
}

export default PaymentRequestAvanceImmediateModalView
