import React from 'react'
import {
  Create, NumberInput, SimpleForm, TextInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const CouponCodeCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect="show">
      <TextInput source="name" label="Nom" />
      <TextInput source="code" label="Code" />
      <NumberInput
        source="customerServiceFeesPercentage"
        label="Frais de service (en %)"
        max={100}
        min={0}
        defaultValue={0}
      />
      <NumberInput source="discountPercentage" label="Réduction (en %)" max={100} min={0} defaultValue={0} />
      <RichTextInput
        source="description"
        label="Description interne (facultatif)"
        toolbar={[['bold', 'italic', 'underline']]}
      />
    </SimpleForm>
  </Create>
)

export default CouponCodeCreate
