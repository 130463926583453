export const banReasons = [
  { id: 'ERREUR', name: 'Erreur' },
  { id: 'SAVOIR_FAIRE', name: 'Savoir faire' },
  { id: 'SAVOIR_ETRE', name: 'Savoir être' },
  { id: 'INTEGRITE', name: 'Intégrité' },
  { id: 'REACTIVITE', name: 'Réactivité' },
  { id: 'FIABILITE', name: 'Fiabilité' },
  { id: 'CLIENT_PARTICULIER', name: 'Client particulier' },
  { id: 'FAUX_COMPTE', name: 'Faux compte' },
  { id: 'HORS_CIBLE', name: 'Hors cible' },
  { id: 'PAS_INTERESSE', name: 'Pas intéressé' },
  { id: 'ENTREPRISE_FERMEE', name: 'Entreprise fermée' },
]

export const marketSegmentations = [
  { id: 'proP', name: 'ProP' },
  { id: 'proEs', name: 'ProEs' },
  { id: 'proVS', name: 'ProVS' },
  { id: 'proOP', name: 'ProOP' },
  { id: 'proCI', name: 'ProCI' },
  { id: 'proCréa', name: 'ProCréa' },
  { id: 'proV', name: 'ProV' },
  { id: 'proSCP', name: 'ProSCP' },
  { id: 'offreAcces', name: 'Offre Accès' },
]

export const proposedServices = [
  { id: 'PONCTUEL', name: 'Ponctuel' },
  { id: 'REGULIER', name: 'Régulier' },
  { id: 'CREATION', name: 'Création' },
  { id: 'ELAGAGE', name: 'Elagage' },
  { id: 'ENTRETIEN', name: 'Entretien' },
  { id: 'TERRASSE_BARDAGE_BOIS', name: 'Terrasse et bardage bois' },
  { id: 'DALLAGE_PAVAGE', name: 'Dallage pavage' },
  { id: 'ARROSAGE_AUTOMATIQUE_INTEGRE', name: 'Arrosage automatique intégré' },
  { id: 'PETITE_MACONNERIE', name: 'Petite maçonnerie' },
  { id: 'CLOTURES', name: 'Clôtures' },
  { id: 'SCP_COACHING', name: 'Coaching SCP' },
  { id: 'SCP_CREATION', name: 'Création SCP' },
  { id: 'BL_X', name: 'BL_X' },
  { id: 'BL_Y', name: 'BL_Y' },
]

export const followUpEmailsSent = [
  { id: 'EMAIL_1', name: 'Email 1' },
  { id: 'EMAIL_2', name: 'Email 2' },
  { id: 'EMAIL_3', name: 'Email 3' },
  { id: 'EMAIL_4', name: 'Email 4' },
  { id: 'EMAIL_5', name: 'Email 5' },
  { id: 'EMAIL_6', name: 'Email 6' },
  { id: 'EMAIL_7', name: 'Email 7' },
  { id: 'EMAIL_8', name: 'Email 8' },
  { id: 'EMAIL_9', name: 'Email 9' },
  { id: 'EMAIL_10', name: 'Email 10' },
]
