import React from 'react'
import Card from '@material-ui/core/Card'
import CardContent from '@material-ui/core/CardContent'
import { Loading, Query } from 'react-admin'
import Grid from '@material-ui/core/Grid'
import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import { ACTIONS } from '../../providers/http/provider'

const payload = {
  sort: { field: 'sumOfPurchaseRequestWithVisitRequired', order: 'ASC' },
}

const useStyles = makeStyles({
  root: {
    maxWidth: 275,
  },
  bullet: {
    display: 'inline-block',
    margin: '0 2px',
    transform: 'scale(0.8)',
  },
  title: {
    fontSize: 14,
  },
  pos: {
    marginBottom: 12,
  },
})

const Dashboard = () => (
  <Query type={ACTIONS.GET_RAW} resource="statistics" payload={{ payload }}>
    {({ data, loading }) => {
      if (loading) {
        return <Loading />
      }
      return (
        <>
          <Grid container spacing={1}>
            <Card className={useStyles.pos} style={{ margin: '28px', backgroundColor: 'lightgreen', width: '100%' }}>
              <Typography variant="h3" align="center" style={{ marginTop: '8px' }} color="textSecondary">
                {data?.sumOfRegisteredUsers} inscrits{' '}
                <small>({data?.sumOfSubscribedNewsletter} inscrits à la newsletter)</small>
              </Typography>
              <Grid container spacing={1}>
                <Grid item xs={2}>
                  <Card className={useStyles.pos} style={{ margin: '28px', backgroundColor: 'seagreen' }}>
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfCustomers}
                        <br />
                        clients
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={2}>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfProfessionals}
                        <br />
                        professionels
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={2}>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center">
                        {data?.sumOfQuotesRealized}
                        <br />
                        devis réalisés
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={2}>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center">
                        {data?.sumOfTransactions}
                        <br />
                        transactions
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
                <Grid item xs={2}>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center">
                        {data?.sumOfPurchaseRequestWithVisitRequired}
                        <br />
                        visites
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>

                <Grid item xs={2}>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center">
                        {data?.sumOfPurchaseRequestWithVisitNotRequired}
                        <br />
                        interventions
                      </Typography>
                    </CardContent>
                  </Card>
                </Grid>
              </Grid>
            </Card>

            <Grid container spacing={1}>
              <Grid item xs={12} sm={6}>
                <Card
                  className={useStyles.pos}
                  style={{ margin: '28px', backgroundColor: 'lightgreen', width: '100%' }}
                >
                  <Typography variant="h3" align="center" style={{ marginTop: '8px' }} color="textSecondary">
                    {data?.sumOfVisitCancellations} annulations de visites
                  </Typography>
                  <Card className={useStyles.pos} style={{ margin: '28px', backgroundColor: 'seagreen' }}>
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfVisitCancellationsForPostponeReason} annulations de pour report
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfVisitCancellationForGardenReason} annulations pour jardin impraticable
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfVisitCancellationForCustomerAbsenceReason} annulations pour absence du client
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfVisitCancellationForPersonalReason} annulations pour raisons personnelles
                      </Typography>
                    </CardContent>
                  </Card>
                </Card>
              </Grid>
              <Grid item xs={12} sm={6}>
                <Card
                  className={useStyles.pos}
                  style={{ margin: '28px', backgroundColor: 'lightgreen', width: '100%' }}
                >
                  <Typography variant="h3" align="center" style={{ marginTop: '8px' }} color="textSecondary">
                    {data?.sumOfInterventionCancellations} annulations d'interventions
                  </Typography>
                  <Card className={useStyles.pos} style={{ margin: '28px', backgroundColor: 'seagreen' }}>
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfInterventionCancellationsForPostponeReason} annulations pour raisons personnelles
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfInterventionCancellationForGardenReason} annulations pour jardin impraticable
                      </Typography>
                    </CardContent>
                  </Card>
                  <Card
                    className={useStyles.pos}
                    style={{ margin: '28px', backgroundColor: 'seagreen', color: 'white' }}
                  >
                    <CardContent>
                      <Typography variant="h5" align="center" style={{ color: 'white' }}>
                        {data?.sumOfInterventionCancellationForPersonalReason} annulations pour raisons personnelles
                      </Typography>
                    </CardContent>
                  </Card>
                </Card>
              </Grid>
            </Grid>
          </Grid>
        </>
      )
    }}
  </Query>
)
export default Dashboard
