import React, { useEffect, useState } from 'react'
import { Button, SimpleForm, useRefresh } from 'react-admin'
import { createForm } from 'final-form'

import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'
import AddCircleOutlineIcon from '@material-ui/icons/AddCircleOutline'
import HighlightOffIcon from '@material-ui/icons/HighlightOff'

import dataProvider from '../../../helpers/dataProvider'

const CashInModalViewOffre1bis = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [comment, setComment] = useState('')
  const [paymentTypeAmounts, setPaymentTypeAmounts] = useState([])
  const [id, setId] = useState(1)
  const [showError, setShowError] = useState(false)
  const [loading, setLoading] = useState(false)
  const refresh = useRefresh()
  useEffect(() => {
    setPaymentTypeAmounts([
      {
        id: getNextId(),
        type: 'CREDIT_CARD',
        amount: record.finalPriceTTC,
      },
    ])
  }, [])

  const getNextId = () => {
    const result = id
    setId(result + 1)
    return result
  }

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleCashIn = () => {
    const filledAmount = Number(
      paymentTypeAmounts
        .map((value) => Number(value.amount !== undefined ? value.amount : 0))
        .reduce((a, b) => a + b, 0)
        .toFixed(2)
    )
    if (filledAmount !== record.finalPriceTTC) {
      setShowError(true)
      return Promise.prototype
    }

    const body = {
      comment,
      paymentTypeAmounts,
    }
    setLoading(true)
    return dataProvider
      .post(`/offre1bis/invoices/${record.id}/cashin`, body)
      .then(() => {
        setShowDialog(false)
        refresh()
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const needToShowButton = () => {
    if (record.creditNote !== undefined) {
      return false
    }
    return record.customerPaymentStatus === 'PAYMENT_ERROR' || record.customerPaymentStatus === 'PAYMENT_WAITING'
  }

  const handleChangePaymentType = (event, id) => {
    setPaymentTypeAmounts(
      paymentTypeAmounts.map((value) => {
        if (value.id === id) {
          value.type = event.target.value
        }
        return value
      })
    )
  }

  const addNewRow = () => {
    setPaymentTypeAmounts(
      paymentTypeAmounts.concat([
        {
          id: getNextId(),
          type: 'CREDIT_CARD',
          amount: 0.0,
        },
      ])
    )
  }

  const deleteRow = (id) => {
    const newPaymentTypeAmounts = paymentTypeAmounts.filter((value) => value.id !== id)
    setPaymentTypeAmounts(newPaymentTypeAmounts)
  }

  const changeInputValue = (event, id) => {
    setPaymentTypeAmounts(
      paymentTypeAmounts.map((value) => {
        if (value.id === id) {
          value.amount = event.target.value
        }
        return value
      })
    )
  }

  const changeComment = (event) => {
    setComment(event.target.value)
  }

  const cashInDialogView = () => (
    <Dialog fullWidth open={showDialog}>
      <DialogTitle>Encaisser le paiement du prélèvement {record.finalPriceTTC}€ !</DialogTitle>
      <DialogContent>
        <SimpleForm
          form={createForm({
            onSubmit: () => {},
          })}
          toolbar={null}
        >
          <TextField label="Comment" type="string" onChange={(event) => changeComment(event)} />
          <br />
          {paymentTypeAmounts.map((value) => (
            <>
              <FormControl>
                <InputLabel id="demo-simple-select-label">Payment type</InputLabel>
                <Select
                  labelId="demo-simple-select-label"
                  id="demo-simple-select"
                  value={value.type}
                  onChange={(event) => handleChangePaymentType(event, value.id)}
                >
                  <MenuItem value="CREDIT_CARD">Carte bancaire</MenuItem>
                  <MenuItem value="BANK_TRANSFER">Virement</MenuItem>
                  <MenuItem value="CESU">CESU</MenuItem>
                  <MenuItem value="CHEQUE">Chèque</MenuItem>
                  <MenuItem value="LOSS_AND_PROFIT">Pertes et Profits</MenuItem>
                  <MenuItem value="VIRTUAL_IBAN">Virtual Iban</MenuItem>
                  <MenuItem value="WALLET">Wallet</MenuItem>
                </Select>
              </FormControl>
              <TextField
                label="amount"
                value={value.amount}
                onChange={(event) => changeInputValue(event, value.id)}
                type="string"
              />
              <HighlightOffIcon onClick={(event) => deleteRow(value.id)} />
            </>
          ))}
          <br />
          <AddCircleOutlineIcon onClick={addNewRow} />
        </SimpleForm>
        <ErrorMessage showError={showError} />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCashIn} label="Encaisser" disabled={loading}>
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler" disabled={loading}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    if (needToShowButton()) {
      return (
        <>
          <Button style={{ padding: '16px' }} onClick={handleClick} label="Encaisser Client">
            <AttachMoneyIcon />
          </Button>
          {cashInDialogView()}
        </>
      )
    }
    return <></>
  }

  return result()
}

const ErrorMessage = (props) => {
  if (props.showError) {
    return <p style={{ color: 'red' }}>Wrong amount</p>
  }
  return <></>
}

export default CashInModalViewOffre1bis
