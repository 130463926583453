import React, { useState } from 'react'
import {
  Datagrid,
  Filter,
  List,
  ReferenceField,
  TextField,
  TextInput,
  FunctionField,
  DateField,
  DateInput,
  TopToolbar,
} from 'react-admin'
import { MuiPickersUtilsProvider, KeyboardDateTimePicker } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import Grid from '@material-ui/core/Grid'
import Button from '@material-ui/core/Button'
import { Clear, Done } from '@material-ui/icons'
import dataProvider from '../../helpers/dataProvider'
import ExportModalView from '../../export/ExportModalView'
import { getResourceUrl, IDENTIFIERS } from '../../config/resources'

const AppointmentFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nom" source="qName" alwaysOn />
    <DateInput label="Début" source="start" alwaysOn />
    <DateInput label="Fin" source="end" alwaysOn />
  </Filter>
)

const ExportActions = (props) => {
  const resource = getResourceUrl(props.basePath.replace('/', ''))
  return (
    <TopToolbar>
      <ExportModalView path={resource} />
    </TopToolbar>
  )
}

const AppointmentList = (props) => {
  const [showButtonsMap, setShowButtonsMap] = useState(new Map())

  const handleChangeData = (data, record) => {
    record.newDate = data
    record.appointmentDate = data
    const newMap = new Map(showButtonsMap)
    newMap.set(record.id, true)
    setShowButtonsMap(newMap)
  }

  const handleUpdateDate = (record) => {
    dataProvider
      .put('/appointments', {
        appointmentId: record.id,
        type: record.type,
        newDate: record.newDate,
      })
      .then((result) => {
        if (result.status === 200) {
          record.appointmentDate = record.newDate
          record.newDate = undefined
          record.monthly = false
          const newMap = new Map(showButtonsMap)
          newMap.set(record.id, false)
          setShowButtonsMap(newMap)
        }
      })
  }

  return (
    <List
      {...props}
      sort={{
        field: 'appointmentDate',
        order: 'DESC',
      }}
      actions={<ExportActions />}
      filters={<AppointmentFilter />}
    >
      <Datagrid>
        <FunctionField
          label="date"
          render={(record) => (
            <MuiPickersUtilsProvider utils={DateFnsUtils}>
              <Grid container justify="space-around">
                <KeyboardDateTimePicker
                  style={{ minWidth: 210 }}
                  format="yyyy-MM-dd HH:mm"
                  value={record.appointmentDate}
                  onChange={(data) => {
                    handleChangeData(data, record)
                  }}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </Grid>
            </MuiPickersUtilsProvider>
          )}
        />
        {props.resource === IDENTIFIERS.APPOINTMENT_INTERVENTION && (
          <FunctionField
            label="Date définie"
            render={(record) => {
              if (record.monthly) {
                return <Clear />
              }
              return <Done />
            }}
          />
        )}
        <FunctionField
          label=""
          render={(record) => {
            if (showButtonsMap.get(record.id)) {
              return (
                <Button variant="contained" color="green" onClick={(event) => handleUpdateDate(record)}>
                  Envoyer
                </Button>
              )
            }
            return null
          }}
        />
        <ReferenceField source="purchaseRequestId" link="show" label="Demande" reference="purchaserequests">
          <TextField source="id" />
        </ReferenceField>
        <ReferenceField source="purchaseRequestId" link="show" label="Statut demande" reference="purchaserequests">
          <TextField source="status" />
        </ReferenceField>
        <DateField locales="fr-FR" source="appointmentDate" showTime label="Statut" />
        <TextField source="status" label="Statut" />
        <TextField source="businessLine" label="Business Line" />

        <ReferenceField label="Client" link="show" source="customerEntityRelationId" reference="customers">
          <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
        </ReferenceField>
        <TextField source="customerCity" label="Ville" />
        {/* <TextField source="customerEmail" label="Mail client" /> */}
        <TextField source="customerPhone" label="Téléphone client" />

        <ReferenceField label="Professionel" link="show" source="proEntityRelationId" reference="professionals">
          <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
        </ReferenceField>
        {/* <TextField source="proEmail" label="Mail prestataire" /> */}
        <TextField source="proPhone" label="Téléphone prestataire" />
      </Datagrid>
    </List>
  )
}

export default AppointmentList
