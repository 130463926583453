import React from 'react'
import {
  SimpleForm, Edit, TextInput, NumberInput, BooleanInput,
} from 'react-admin'

const MenuParametersEdit = (props) => (
  <Edit {...props}>
    <SimpleForm>
      <TextInput source="title" label="Titre" />
      <TextInput source="link" label="Lien URL" />
      <NumberInput source="priority" label="Priorité" />
      <BooleanInput source="webview" label="Webview ?" />
    </SimpleForm>
  </Edit>
)

export default MenuParametersEdit
