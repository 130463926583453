import { makeStyles } from '@material-ui/core/styles'
import Typography from '@material-ui/core/Typography'
import React from 'react'

const useStyles = makeStyles(() => ({
  fieldTitle: {
    color: 'rgba(0, 0, 0, 0.54)',
    fontSize: '12px',
    marginTop: '10px',
  },
}))

const FieldTitleShow = ({ title }) => {
  const classes = useStyles()
  return <Typography className={classes.fieldTitle}>{title}</Typography>
}

export default FieldTitleShow
