import React from 'react'
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  List,
  NumberField,
  ReferenceField,
  ReferenceInput,
  SelectInput,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'

import ExportModalView from '../../export/ExportModalView'

const PurchaseRequestFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nom client" source="qName" alwaysOn />
    <TextInput label="Id" source="qId" alwaysOn />
    <DateInput label="Début" source="start" alwaysOn />
    <DateInput label="Fin" source="end" alwaysOn />
    <ReferenceInput
      label="Offre"
      source="serviceLevel"
      reference="enums"
      key="serviceLevel"
      filter={{ enum: 'serviceLevels' }}
    >
      <SelectInput optionText="name" />
    </ReferenceInput>
  </Filter>
)

const ExportActions = () => (
  <TopToolbar>
    <ExportModalView path="purchaserequests" />
  </TopToolbar>
)

const PurchaseRequestList = (props) => (
  <List
    {...props}
    perPage={25}
    sort={{ field: 'createdDate', order: 'DESC' }}
    actions={<ExportActions />}
    filters={<PurchaseRequestFilter />}
  >
    <Datagrid rowClick="show">
      <ReferenceField label="id" link="show" source="id" reference="purchaserequests">
        <TextField source="id" />
      </ReferenceField>
      <TextField source="businessLine" label="Business Line" />
      <BooleanField source="visitRequired" label="Avec visite" />
      <TextField source="status" label="État" />
      <DateField locales="fr-FR" showTime source="createdDate" label="Date de création" />
      <ReferenceField label="Prénom" link="show" source="customerEntityRelationId" reference="customers">
        <TextField source="profile.firstName" />
      </ReferenceField>
      <ReferenceField label="Nom" link="show" source="customerEntityRelationId" reference="customers">
        <TextField source="profile.lastName" />
      </ReferenceField>
      <TextField source="address.zipCode" label="Code postal" />
      <DateField locales="fr-FR" source="nextAppointment" label="Prochain RDV" />
      <TextField source="serviceLevel" label="Offre" />

      <NumberField source="visitCounter.numberOfWaitingResponse" label="Visites en attente" />
      <NumberField source="visitCounter.numberOfAcceptResponse" label="Visites acceptées" />
      <NumberField source="visitCounter.numberOfTerminatedResponse" label="Visites terminées" />
      <NumberField source="visitCounter.numberOfCancelledResponse" label="Visites annulées" />

      <NumberField source="interventionCounter.numberOfWaitingResponse" label="Devis en attente" />
      <NumberField source="interventionCounter.numberOfAcceptResponse" label="Devis acceptés" />
      <NumberField source="interventionCounter.numberOfTerminatedResponse" label="Devis terminés" />
      <NumberField source="interventionCounter.numberOfCancelledResponse" label="Devis annulés" />
    </Datagrid>
  </List>
)

export default PurchaseRequestList
