import React from 'react'
import { Login } from 'react-admin'
/* eslint-disable-next-line */
import { withStyles } from '@material-ui/core/styles'

const bg = require('../../assets/images/wave.jpg')

const styles = {
  container: {
    backgroundPosition: 'center',
  },
}

const CustomLogin = withStyles({
  card: { marginTop: '13rem' },
})(Login)

export const LoginPage = withStyles(styles)(({ classes }) => (
  <CustomLogin className={classes.container} backgroundImage={bg} />
))
