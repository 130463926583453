import React, { useState } from 'react'
import { Button, useRefresh } from 'react-admin'

import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import { FormControlLabel, Switch } from '@material-ui/core'
import Typography from '@material-ui/core/Typography'
import dataProvider from '../../../helpers/dataProvider'

const CashOutModalViewOffre1bis = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [transactionAlreadyDoneOutsideSystem, setTransactionAlreadyDoneOutsideSystem] = useState(false)
  const refresh = useRefresh()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleCashOut = () => dataProvider
    .post(
      `/offre1bis/invoices/${record.id}/cashout?already_done_outside_system=${transactionAlreadyDoneOutsideSystem}`
    )
    .then(() => {
      setShowDialog(false)
      refresh()
    })

  const needToShowButton = () => {
    if (record.creditNote !== undefined) {
      return false
    }
    return record.proPaymentStatus === 'PAYMENT_ERROR' || record.proPaymentStatus === 'PAYMENT_WAITING'
  }

  const cashInDialogView = () => (
    <Dialog fullWidth open={showDialog}>
      <DialogTitle>Envoyer le paiement de {record.proATJInvoicePriceTTC}€ au pro ?</DialogTitle>
      <DialogContent>
        <FormControlLabel
          control={(
            <Switch
              checked={transactionAlreadyDoneOutsideSystem}
              onChange={(e) => {
                setTransactionAlreadyDoneOutsideSystem(e.target.checked)
              }}
            />
          )}
          label="Transaction déjà effectuée en dehors du système ?"
          labelPlacement="start"
        />

        {transactionAlreadyDoneOutsideSystem ? (
          <Typography variant="body1" color="error">
            Attention : vous indiquez ici que la transaction à déjà été envoyée au pro en dehors du système, aucun
            virement ne sera effectué vers son compte
          </Typography>
        ) : null}
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCashOut} label="Encaisser">
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler">
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    if (needToShowButton()) {
      return (
        <>
          <Button style={{ padding: '16px' }} onClick={handleClick} label="Encaisser Pro">
            <AttachMoneyIcon />
          </Button>
          {cashInDialogView()}
        </>
      )
    }
    return <></>
  }
  return result()
}

export default CashOutModalViewOffre1bis
