import React from 'react'
import {
  ArrayField,
  BooleanField,
  Datagrid,
  DateField,
  NumberField,
  Show,
  SimpleShowLayout,
  Tab,
  TabbedShowLayout,
  UrlField,
  TextField,
} from 'react-admin'

const WalletShow = (props) => (
  <Show {...props}>
    <TabbedShowLayout>
      <Tab label="Informations">
        <SimpleShowLayout>
          <TextField source="idProviderReference" label="Identifiant ATJ (id externe)" />
          <TextField source="idProviderTechnical" label="Identifiant Lemonway" />
          <TextField source="status" label="Statut" />
          <UrlField source="lemonwayUrl" label="Lien lemonway" target="_blank" />
          <NumberField source="balance" label="Solde" options={{ style: 'currency', currency: 'EUR' }} />
          <BooleanField source="active" label="Actif" />
        </SimpleShowLayout>
      </Tab>
      <Tab label="transactions">
        <ArrayField source="transactions">
          <Datagrid>
            <DateField locales="fr-FR" source="createdDate" label="Date création" />
            <TextField source="status" label="Statut" />
            <TextField source="type" label="Type" />
            <DateField locales="fr-FR" source="completionDate" label="Date finalisation" />
            <DateField locales="fr-FR" source="plannedDate" label="Date planifiée" />
            <NumberField source="amount" label="Montant" options={{ style: 'currency', currency: 'EUR' }} />
            <TextField source="comments" label="Commentaire" />
            <TextField source="commentsInternal" label="Commentaire interne" />
            <TextField source="error" label="Erreur" />
            <TextField source="providerId" label="# Lemonway" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Documents">
        <ArrayField source="documents">
          <Datagrid>
            <DateField locales="fr-FR" source="createdDate" label="Date création" />
            <TextField source="providerId" label="# Lemonway" />
            <TextField source="status" label="Statut" />
            <TextField source="type" label="Type" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="Mandats de prélèvement">
        <ArrayField source="sddMandates">
          <Datagrid>
            <DateField locales="fr-FR" source="createdDate" label="Date création" />
            <TextField source="providerId" label="# Lemonway" />
            <TextField source="status" label="Statut" />
            <BooleanField source="usable" label="Utilisable" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="IBAN">
        <ArrayField source="ibans">
          <Datagrid>
            <DateField locales="fr-FR" source="createdDate" label="Date création" />
            <TextField source="providerId" label="# Lemonway" />
            <TextField source="status" label="Statut" />
            <TextField source="holder" label="Titulaire" />
            <TextField source="iban" label="# iban" />
            <TextField source="swift" label="# bic" />
          </Datagrid>
        </ArrayField>
      </Tab>
      <Tab label="CB">
        <ArrayField source="cards">
          <Datagrid>
            <DateField locales="fr-FR" source="createdDate" label="Date création" />
            <TextField source="providerId" label="# Lemonway" />
            <TextField source="number" label="#" />
            <TextField source="country" label="Pays" />
            <TextField source="expiration" label="Expiration" />
            <TextField source="type" label="Type" />
          </Datagrid>
        </ArrayField>
      </Tab>
    </TabbedShowLayout>
  </Show>
)

export default WalletShow
