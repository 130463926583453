import React, { useState } from 'react'
import { Button } from 'react-admin'
import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogActions from '@material-ui/core/DialogActions'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'
import DialogContent from '@material-ui/core/DialogContent'
import TextField from '@material-ui/core/TextField'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import { FormControlLabel, Switch } from '@material-ui/core'
import dataProvider from '../../../helpers/dataProvider'
import { paymentTypes } from '../../../enums/PaymentType'

const AcceptPonctuelPurchaseModal = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [transactionOutsideSystem, setTransactionOutsideSystem] = useState(false)
  const [amount, setAmount] = useState(0)
  const [paymentType, setPaymentType] = useState('CREDIT_CARD')

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const needToShowButton = () => record.recurring === false && record.status === 'P_UNANSWERED'

  const handleConfirmClick = () => {
    setLoading(true)
    dataProvider
      .post(`/purchases/${record.id}/accept-ponctuel`, {
        depositAmount: amount,
        paymentType,
        paymentOutsideSystem: transactionOutsideSystem,
      })
      .then(() => {
        setShowDialog(false)
      })
      .finally(() => {
        setLoading(false)
        window.location.reload()
      })
  }

  const acceptPonctuelPurchaseModal = (label) => (
    <Dialog fullWidth open={showDialog}>
      <DialogTitle>{label}</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          id="deposit-amount"
          label="Montant de l'acompte"
          type="number"
          fullWidth
          aria-valuemax={record.totalPriceTTC}
          aria-valuemin={0}
          value={amount}
          onChange={(e) => setAmount(e.target.value)}
          style={{ marginBottom: '30px' }}
        />
        <InputLabel fullWidth id="deposit-payment-type-label">
          Type de paiement
        </InputLabel>
        <Select
          label="Type de paiement"
          labelId="deposit-payment-type-label"
          id="deposit-payment-type"
          fullWidth
          value={paymentType}
          onChange={(e) => setPaymentType(e.target.value)}
        >
          {Object.keys(paymentTypes).map((key) => (
            <MenuItem key={key} value={key}>
              {paymentTypes[key]}
            </MenuItem>
          ))}
        </Select>
        <FormControlLabel
          style={{ marginTop: '20px' }}
          label="Transaction déjà effectuée en dehors du système ?"
          control={(
            <Switch
              checked={transactionOutsideSystem}
              onChange={(e) => {
                setTransactionOutsideSystem(e.target.checked)
              }}
            />
          )}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirmClick} label="Confirmer" disabled={loading}>
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler" disabled={loading}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = "Accepter (paiement de l'acompte)"
    const needToShow = needToShowButton()
    return (
      <>
        {needToShow && (
          <Button onClick={handleClick} label={label} style={{ marginTop: '10px' }}>
            <AttachMoneyIcon />
          </Button>
        )}
        {acceptPonctuelPurchaseModal(label)}
      </>
    )
  }

  return result()
}

export default AcceptPonctuelPurchaseModal
