export const CodeTypeVoieResidence = [
  {
    code: '',
    label: '(non défini)',
  },
  {
    code: 'R',
    label: 'Rue',
  },
  {
    code: 'ALL',
    label: 'Allée',
  },
  {
    code: 'AV',
    label: 'Avenue',
  },
  {
    code: 'BAT',
    label: 'Batiment',
  },
  {
    code: 'BD',
    label: 'Boulevard',
  },
  {
    code: 'C',
    label: 'Cours',
  },
  {
    code: 'CAN',
    label: 'Canal',
  },
  {
    code: 'CHE',
    label: 'Chemin',
  },
  {
    code: 'CHS',
    label: 'Chaussée',
  },
  {
    code: 'CI',
    label: 'Cité',
  },
  {
    code: 'CL',
    label: 'Clos',
  },
  {
    code: 'COG',
    label: 'Cottage',
  },
  {
    code: 'FG',
    label: 'Faubourg',
  },
  {
    code: 'HAM',
    label: 'Hameau',
  },
  {
    code: 'IMB',
    label: 'Immeuble',
  },
  {
    code: 'IMP',
    label: 'Impasse',
  },
  {
    code: 'LOT',
    label: 'Lotissement',
  },
  {
    code: 'MAI',
    label: 'Maison',
  },
  {
    code: 'MTE',
    label: 'Montée',
  },
  {
    code: 'PAR',
    label: 'Parc',
  },
  {
    code: 'PAS',
    label: 'Passage',
  },
  {
    code: 'PAV',
    label: 'Pavillon',
  },
  {
    code: 'PL',
    label: 'Place',
  },
  {
    code: 'POR',
    label: 'Porte',
  },
  {
    code: 'Q',
    label: 'Quai',
  },
  {
    code: 'QU',
    label: 'Quartier',
  },
  {
    code: 'RAM',
    label: 'Rampe',
  },
  {
    code: 'RES',
    label: 'Résidence',
  },
  {
    code: 'RTE',
    label: 'Route',
  },
  {
    code: 'RUL',
    label: 'Ruelle',
  },
  {
    code: 'SEN',
    label: 'Sentier',
  },
  {
    code: 'SQ',
    label: 'Square',
  },
  {
    code: 'VO',
    label: 'Voie',
  },
  {
    code: 'CAS',
    label: 'CASERNE',
  },
  {
    code: 'CAV',
    label: 'CAVEE',
  },
  {
    code: 'CEI',
    label: 'CEINTURE',
  },
  {
    code: 'CEL',
    label: 'CELLIER',
  },
  {
    code: 'CEN',
    label: 'CENTRE',
  },
  {
    code: 'CGE',
    label: 'COLLEGE',
  },
  {
    code: 'CH',
    label: 'CHASSE',
  },
  {
    code: 'CHA',
    label: 'CHATEAU',
  },
  {
    code: 'CHN',
    label: 'CHANTIER',
  },
  {
    code: 'CHP',
    label: 'CHAPELLE',
  },
  {
    code: 'CHT',
    label: 'CHEMINEMENT',
  },
  {
    code: 'CHV',
    label: 'CHEMIN VICINAL',
  },
  {
    code: 'CIM',
    label: 'CIMETIERE',
  },
  {
    code: 'CIN',
    label: 'CINEMA',
  },
  {
    code: 'CLB',
    label: 'CLUB',
  },
  {
    code: 'CLI',
    label: 'CLINIQUE',
  },
  {
    code: 'CLL',
    label: 'COL',
  },
  {
    code: 'CLO',
    label: 'CLOITRE',
  },
  {
    code: 'CN',
    label: 'COIN',
  },
  {
    code: 'CO',
    label: 'COUR',
  },
  {
    code: 'COE',
    label: 'COLLINE',
  },
  {
    code: 'COL',
    label: 'COLONIE',
  },
  {
    code: 'COM',
    label: 'COMMISSARIAT',
  },
  {
    code: 'COR',
    label: 'CORNICHE',
  },
  {
    code: 'COT',
    label: 'COTE',
  },
  {
    code: 'COU',
    label: 'COULOIR',
  },
  {
    code: 'CRO',
    label: 'CARROI',
  },
  {
    code: 'CRX',
    label: 'CROIX',
  },
  {
    code: 'CTE',
    label: 'CENTRAL TELEPHONIQUE',
  },
  {
    code: 'CUR',
    label: 'CURE',
  },
  {
    code: 'DEG',
    label: 'DEGRE',
  },
  {
    code: 'DEP',
    label: 'DEPOT',
  },
  {
    code: 'DES',
    label: 'DESCENTE',
  },
  {
    code: 'DIG',
    label: 'DIGUE',
  },
  {
    code: 'DOM',
    label: 'DOMAINE',
  },
  {
    code: 'DON',
    label: 'DIRECTION',
  },
  {
    code: 'ECA',
    label: 'ECART',
  },
  {
    code: 'ECL',
    label: 'ECLUSE',
  },
  {
    code: 'ECO',
    label: 'ECOLE',
  },
  {
    code: 'ECV',
    label: 'ENCLAVE',
  },
  {
    code: 'EGL',
    label: 'EGLISE',
  },
  {
    code: 'EMP',
    label: 'EMPLACEMENT',
  },
  {
    code: 'EN',
    label: 'ENCEINTE',
  },
  {
    code: 'ENC',
    label: 'ENCLOS',
  },
  {
    code: 'ENT',
    label: 'ENTREPOT',
  },
  {
    code: 'ESC',
    label: 'ESCALIER',
  },
  {
    code: 'ESP',
    label: 'ESPLANADE',
  },
  {
    code: 'ETA',
    label: 'ETABLISSEMENT',
  },
  {
    code: 'ETG',
    label: 'ETANG',
  },
  {
    code: 'FAC',
    label: 'FACADE',
  },
  {
    code: 'FCU',
    label: 'FACULTE',
  },
  {
    code: 'FER',
    label: 'FERME',
  },
  {
    code: 'FOL',
    label: 'FOLIE',
  },
  {
    code: 'FON',
    label: 'FONTAINE',
  },
  {
    code: 'FOR',
    label: 'FORET',
  },
  {
    code: 'FOS',
    label: 'FOSSE',
  },
  {
    code: 'FOY',
    label: 'FOYER',
  },
  {
    code: 'FRI',
    label: 'FRICHE',
  },
  {
    code: 'FT',
    label: 'FORT',
  },
  {
    code: 'GA',
    label: 'GARE',
  },
  {
    code: 'GAL',
    label: 'GALERIE',
  },
  {
    code: 'GDI',
    label: 'GRAND IMPASSE',
  },
  {
    code: 'GED',
    label: 'GENDARMERIE',
  },
  {
    code: 'GEN',
    label: 'GRAND ENSEMBLE',
  },
  {
    code: 'GGE',
    label: 'GARAGE',
  },
  {
    code: 'GLA',
    label: 'GLACIS',
  },
  {
    code: 'GPE',
    label: 'GROUPE',
  },
  {
    code: 'GR',
    label: 'GRAND RUE',
  },
  {
    code: 'GRI',
    label: 'GRILLE',
  },
  {
    code: 'GRN',
    label: 'GARENNE',
  },
  {
    code: 'GRP',
    label: 'GROUPEMENT',
  },
  {
    code: 'GSC',
    label: 'GROUPE SCOLAIRE',
  },
  {
    code: 'GUE',
    label: 'GUE',
  },
  {
    code: 'HA',
    label: 'HALAGE',
  },
  {
    code: 'HAL',
    label: 'HALLE',
  },
  {
    code: 'HCE',
    label: 'HOSPICE',
  },
  {
    code: 'HIP',
    label: 'HIPPODROME',
  },
  {
    code: 'HLM',
    label: 'H.L.M.',
  },
  {
    code: 'HOP',
    label: 'HOPITAL',
  },
  {
    code: 'HOT',
    label: 'HOTEL',
  },
  {
    code: 'HUT',
    label: 'HUTTE',
  },
  {
    code: 'ILE',
    label: 'ILE',
  },
  {
    code: 'ILO',
    label: 'ILOT',
  },
  {
    code: 'INS',
    label: 'INSTITUT',
  },
  {
    code: 'ISP',
    label: 'INSPECTION',
  },
  {
    code: 'JAR',
    label: 'JARDIN',
  },
  {
    code: 'JET',
    label: 'JETEE',
  },
  {
    code: 'JUS',
    label: 'JUSTICE',
  },
  {
    code: 'LD',
    label: 'LIEU DIT',
  },
  {
    code: 'LEV',
    label: 'LEVEE',
  },
  {
    code: 'LYC',
    label: 'LYCEE',
  },
  {
    code: 'MA',
    label: 'MARE',
  },
  {
    code: 'MAG',
    label: 'MAGASIN',
  },
  {
    code: 'MAN',
    label: 'MANOIR',
  },
  {
    code: 'MAR',
    label: 'MARCHE',
  },
  {
    code: 'MAS',
    label: 'MAS',
  },
  {
    code: 'MAT',
    label: 'MATERNITE',
  },
  {
    code: 'MET',
    label: 'METRO',
  },
  {
    code: 'MF',
    label: 'MAISON FORESTIERE',
  },
  {
    code: 'MG',
    label: 'MAISON DE GARDE',
  },
  {
    code: 'ML',
    label: 'MAIL',
  },
  {
    code: 'MOU',
    label: 'MOULIN',
  },
  {
    code: 'MRI',
    label: 'MAIRIE',
  },
  {
    code: 'MUS',
    label: 'MUSEE',
  },
  {
    code: 'NTE',
    label: 'NOUVELLE ROUTE',
  },
  {
    code: 'OBS',
    label: 'OBSERVATOIRE',
  },
  {
    code: 'OCT',
    label: 'OCTROI',
  },
  {
    code: 'PA',
    label: 'PARVIS',
  },
  {
    code: 'PAE',
    label: 'PETITE AVENUE',
  },
  {
    code: 'PAL',
    label: 'PALAIS',
  },
  {
    code: 'PAT',
    label: 'PATURE',
  },
  {
    code: 'PBY',
    label: 'PRESBYTERE',
  },
  {
    code: 'PCE',
    label: 'PORCHE',
  },
  {
    code: 'PER',
    label: 'PERYSTILE',
  },
  {
    code: 'PHA',
    label: 'PHARMACIE',
  },
  {
    code: 'PIE',
    label: 'PIECE',
  },
  {
    code: 'PIM',
    label: 'PETITE IMPASSE',
  },
  {
    code: 'PKG',
    label: 'PARKING',
  },
  {
    code: 'PLA',
    label: 'PLAINE',
  },
  {
    code: 'PLC',
    label: 'PLACIS',
  },
  {
    code: 'PLE',
    label: 'PASSERELLE',
  },
  {
    code: 'PLT',
    label: 'PLATEAU',
  },
  {
    code: 'PLY',
    label: 'PETIT LYCEE',
  },
  {
    code: 'PO',
    label: 'PONT',
  },
  {
    code: 'POI',
    label: 'POINTE',
  },
  {
    code: 'POT',
    label: 'POTERNE',
  },
  {
    code: 'POU',
    label: 'POURTOUR',
  },
  {
    code: 'PPA',
    label: 'PETIT PASSAGE',
  },
  {
    code: 'PR',
    label: 'PETITE RUE',
  },
  {
    code: 'PRA',
    label: 'PRAIRIE',
  },
  {
    code: 'PRE',
    label: 'PRE',
  },
  {
    code: 'PRO',
    label: 'PROMENADE',
  },
  {
    code: 'PRQ',
    label: 'PRESQUILE',
  },
  {
    code: 'PRT',
    label: 'PETITE ROUTE',
  },
  {
    code: 'PRU',
    label: 'PREAU',
  },
  {
    code: 'PT',
    label: 'PORT',
  },
  {
    code: 'PTE',
    label: 'POSTE',
  },
  {
    code: 'RAC',
    label: 'RACCOURCI',
  },
  {
    code: 'REM',
    label: 'REMPART',
  },
  {
    code: 'RNT',
    label: 'RESTAURANT',
  },
  {
    code: 'ROC',
    label: 'ROCADE',
  },
  {
    code: 'ROQ',
    label: 'ROQUET',
  },
  {
    code: 'ROT',
    label: 'ROTONDE',
  },
  {
    code: 'ROU',
    label: 'ROULADE',
  },
  {
    code: 'RP',
    label: 'ROND POINT',
  },
  {
    code: 'SAN',
    label: 'SANATORIUM',
  },
  {
    code: 'SAP',
    label: 'SAPUM',
  },
  {
    code: 'SAS',
    label: 'SAS',
  },
  {
    code: 'SCE',
    label: 'SERVICE',
  },
  {
    code: 'SEC',
    label: 'SECTION',
  },
  {
    code: 'SEM',
    label: 'SEMINAIRE',
  },
  {
    code: 'SNT',
    label: 'SENTE',
  },
  {
    code: 'SOC',
    label: 'SOCIETE',
  },
  {
    code: 'STA',
    label: 'STADE',
  },
  {
    code: 'STN',
    label: 'STAND',
  },
  {
    code: 'STO',
    label: 'STATION',
  },
  {
    code: 'TE',
    label: 'TERRASSE',
  },
  {
    code: 'TEA',
    label: 'TERRAIN',
  },
  {
    code: 'TEN',
    label: 'TENUE',
  },
  {
    code: 'TER',
    label: 'TERTRE',
  },
  {
    code: 'THE',
    label: 'THEATRE',
  },
  {
    code: 'TOU',
    label: 'TOUR',
  },
  {
    code: 'TP',
    label: 'TERRE PLEIN',
  },
  {
    code: 'TRA',
    label: 'TRAVERSE',
  },
  {
    code: 'TRG',
    label: 'TRIEGE',
  },
  {
    code: 'TRI',
    label: 'TRIAGE',
  },
  {
    code: 'TUI',
    label: 'TUILERIE',
  },
  {
    code: 'USI',
    label: 'USINE',
  },
  {
    code: 'VAL',
    label: 'VAL',
  },
  {
    code: 'VCH',
    label: 'VIEUX CHEMIN',
  },
  {
    code: 'VEL',
    label: 'VELODROME',
  },
  {
    code: 'VEN',
    label: 'VENELLE',
  },
  {
    code: 'VGE',
    label: 'VILLAGE',
  },
  {
    code: 'VIA',
    label: 'VIADUC',
  },
  {
    code: 'VIL',
    label: 'VILLE',
  },
  {
    code: 'VLA',
    label: 'VILLA',
  },
  {
    code: 'VLE',
    label: 'VALLEE',
  },
  {
    code: 'VNO',
    label: 'VILLE NOUVELLE',
  },
  {
    code: 'VON',
    label: 'VALLON',
  },
  {
    code: 'VOY',
    label: 'VOYEUL',
  },
  {
    code: 'ZAC',
    label: 'ZAC',
  },
  {
    code: 'ZI',
    label: 'ZONE INDUSTRIELLE',
  },
  {
    code: 'ZUP',
    label: 'Z.U.P.',
  },
]
