import React from 'react'
import {
  ArrayInput, Edit, SimpleForm, SimpleFormIterator, TextInput,
} from 'react-admin'

const CommercialAreasEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput source="nomZone" label="Région" />
      <TextInput source="contactName" label="Prénom du contact" />
      <ArrayInput source="contactEmails" label="Emails du contact">
        <SimpleFormIterator>
          <TextInput label="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="contactPhones" label="Téléphones du contact">
        <SimpleFormIterator>
          <TextInput label="" />
        </SimpleFormIterator>
      </ArrayInput>
      <ArrayInput source="departementsNumbers" label="Départements">
        <SimpleFormIterator>
          <TextInput label="" />
        </SimpleFormIterator>
      </ArrayInput>
    </SimpleForm>
  </Edit>
)

export default CommercialAreasEdit
