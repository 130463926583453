/* eslint-disable */
import React, { useState } from 'react'
import { Button, SimpleForm } from 'react-admin'
import { createForm } from 'final-form'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import TextField from '@material-ui/core/TextField'

import SendIcon from '@material-ui/icons/Send'
import { ListAlt } from '@material-ui/icons'
import IconCancel from '@material-ui/icons/Cancel'
import MenuItem from '@material-ui/core/MenuItem'
import Select from '@material-ui/core/Select'
import InputLabel from '@material-ui/core/InputLabel'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import DateFnsUtils from '@date-io/date-fns'
import dataProvider from '../../../helpers/dataProvider'
import { CodeTypeVoieResidence } from '../../../constants/AvanceImmediate'

const AvanceImmediateInscriptionFormModalView = ({ record, customerId, customer1bisId }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [loading, setLoading] = useState(false)
  const [showFormState, setFormState] = useState({
    civilite: 'MONSIEUR',
    nomNaissance: '',
    nomUsage: '',
    prenoms: '',
    dateNaissance: null,
    numeroTelephonePortable: null,
    adresseMail: '',
    codePaysIso2Naissance: 'fr',
    villeNaissanceLibelle: '',
    villeNaissanceCodePostal: '',
    codePaysIso2Residence: 'fr',
    villeResidenceLibelle: '',
    villeResidenceCodePostal: '',
    lieuDitResidence: '',
    numeroVoieResidence: '',
    libelleVoieResidence: '',
    complementResidence: '',
    codeTypeVoieResidence: 'R',
    lettreVoieResidence: '',
    bic: '',
    iban: '',
    titulaire: '',
  })
  const modifyFormState = (key, value) => {
    setFormState({
      ...showFormState,
      [key]: value,
    })
  }

  const handleClick = () => {
    if (record.avanceImmediate && record.avanceImmediate && record.avanceImmediate.requestBody) {
      handleReloadData()
    }
    setShowDialog(true)
  }

  const handleReloadData = () => {
    const data = JSON.parse(record.avanceImmediate.requestBody)
    setFormState({
      civilite: data.civilite,
      nomNaissance: data.nomNaissance,
      nomUsage: data.nomUsage,
      prenoms: data.prenoms,
      dateNaissance: new Date(data.dateNaissance),
      numeroTelephonePortable: data.numeroTelephonePortable,
      adresseMail: data.adresseMail,
      codePaysIso2Naissance: data.lieuNaissance ? data.lieuNaissance.codePays : '',
      villeNaissanceLibelle: data.lieuNaissance ? data.lieuNaissance.libelleCommune : '',
      villeNaissanceCodePostal: data.lieuNaissance ? data.lieuNaissance.codePostal : '',
      codePaysIso2Residence: data.adressePostale ? data.adressePostale.codePays : '',
      villeResidenceLibelle: data.adressePostale ? data.adressePostale.libelleCommune : '',
      villeResidenceCodePostal: data.adressePostale ? data.adressePostale.codePostal : '',
      lieuDitResidence: data.adressePostale ? data.adressePostale.lieuDit : '',
      numeroVoieResidence: data.adressePostale ? data.adressePostale.numeroVoie : '',
      libelleVoieResidence: data.adressePostale ? data.adressePostale.libelleVoie : '',
      complementResidence: data.adressePostale ? data.adressePostale.complement : '',
      codeTypeVoieResidence: data.adressePostale ? data.adressePostale.codeTypeVoie : '',
      lettreVoieResidence: data.adressePostale ? data.adressePostale.lettreVoie : '',
      bic: data.coordonneeBancaire ? data.coordonneeBancaire.bic : '',
      iban: data.coordonneeBancaire ? data.coordonneeBancaire.iban : '',
      titulaire: data.coordonneeBancaire ? data.coordonneeBancaire.titulaire : '',
    })
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSend = () => {
    setLoading(true)
    const body = {
      civilite: showFormState.civilite,
      nomNaissance: showFormState.nomNaissance,
      nomUsage: showFormState.nomUsage,
      prenoms: showFormState.prenoms,
      dateNaissance: showFormState.dateNaissance.toISOString().split('T')[0],
      numeroTelephonePortable: showFormState.numeroTelephonePortable,
      adresseMail: showFormState.adresseMail,
      lieuNaissance: {
        codeIsoPays: showFormState.codePaysIso2Naissance,
        libelleCommune: showFormState.villeNaissanceLibelle,
        codePostal: showFormState.villeNaissanceCodePostal,
      },
      adressePostale: {
        codeIsoPays: showFormState.codePaysIso2Residence,
        libelleCommune: showFormState.villeResidenceLibelle,
        codePostal: showFormState.villeResidenceCodePostal,
        numeroVoie: showFormState.numeroVoieResidence,
        libelleVoie: showFormState.libelleVoieResidence,
        lieuDit: showFormState.lieuDitResidence,
        complement: showFormState.complementResidence,
        codeTypeVoie: showFormState.codeTypeVoieResidence || null,
        lettreVoie: showFormState.lettreVoieResidence || null,
      },
      coordonneeBancaire: {
        bic: showFormState.bic,
        iban: showFormState.iban,
        titulaire: showFormState.titulaire,
      },
    }
    if (customerId) {
      dataProvider
        .post(`/avance-immediate/customer/${customerId}/infos`, body)
        .then(() => {
          window.alert('Les informations ont bien été envoyées.')
          window.location.reload()
        })
        .catch(() => {
          window.alert("Une erreur est survenue lors de l'envoi des informations.")
          window.location.reload()
        })
        .finally(() => {
          setLoading(false)
        })
    }
    if (customer1bisId) {
      dataProvider
        .post(`/avance-immediate/customer1bis/${customer1bisId}/infos`, body)
        .then(() => {
          window.alert('Les informations ont bien été envoyées.')
          window.location.reload()
        })
        .catch(() => {
          window.alert("Une erreur est survenue lors de l'envoi des informations.")
          window.location.reload()
        })
    }
  }

  const needToShowButton = () => !record.avanceImmediate || !record.avanceImmediate.informationProvided

  const avanceImmediateDialogView = () => (
    <Dialog
      fullScreen
      open={showDialog}
      style={{
        paddingBottom: '4em',
      }}
    >
      <DialogTitle>Envoyer les informations sur le client vers URSSAF</DialogTitle>
      <DialogContent>
        <SimpleForm
          form={createForm({
            onSubmit: () => {},
          })}
          toolbar={null}
        >
          <h4
            style={{
              marginBottom: '0.5em',
            }}
          >
            Informations générales
          </h4>
          <InputLabel fullWidth id="select-civilite-label">
            Civilite
          </InputLabel>
          <Select
            label="Civilite"
            labelId="select-civilite-label"
            id="select-civilite"
            value={showFormState.civilite}
            onChange={(e) => modifyFormState('civilite', e.target.value)}
          >
            <MenuItem value="MONSIEUR">Monsieur</MenuItem>
            <MenuItem value="MADAME">Madame</MenuItem>
          </Select>
          <TextField
            fullWidth
            placeholder="Girard"
            label="Nom de naissance"
            type="string"
            value={showFormState.nomNaissance}
            onChange={(e) => modifyFormState('nomNaissance', e.target.value)}
          />
          <TextField
            fullWidth
            placeholder="Durrand"
            label="Nom d'usage (si différent du nom de naissance)"
            type="string"
            value={showFormState.nomUsage}
            onChange={(e) => modifyFormState('nomUsage', e.target.value)}
          />
          <TextField
            fullWidth
            placeholder="Claire Manon Gisèle"
            label="Prénoms"
            type="string"
            value={showFormState.prenoms}
            onChange={(e) => modifyFormState('prenoms', e.target.value)}
          />
          <MuiPickersUtilsProvider utils={DateFnsUtils}>
            <KeyboardDatePicker
              variant="inline"
              format="dd/MM/yyyy"
              margin="normal"
              id="birthdate"
              label="Date de naissance"
              value={showFormState.dateNaissance}
              onChange={(e) => modifyFormState('dateNaissance', e)}
              KeyboardButtonProps={{
                'aria-label': 'change date',
              }}
            />
          </MuiPickersUtilsProvider>
          <TextField
            fullWidth
            placeholder="fr"
            label="Code iso à deux lettres du pays de naissance"
            type="string"
            value={showFormState.codePaysIso2Naissance}
            onChange={(e) => modifyFormState('codePaysIso2Naissance', e.target.value)}
          />
          {showFormState.codePaysIso2Naissance.toLowerCase() === 'fr' && (
            <>
              <TextField
                fullWidth
                placeholder="Paris"
                label="Ville de naissance"
                type="string"
                value={showFormState.villeNaissanceLibelle}
                onChange={(e) => modifyFormState('villeNaissanceLibelle', e.target.value)}
              />
              <TextField
                fullWidth
                placeholder="75000"
                label="Code postal de naissance"
                type="string"
                value={showFormState.villeNaissanceCodePostal}
                onChange={(e) => modifyFormState('villeNaissanceCodePostal', e.target.value)}
              />
            </>
          )}
          <h4
            style={{
              marginBottom: '0.5em',
              marginTop: '1em',
            }}
          >
            Informations de contact
          </h4>
          <TextField
            fullWidth
            placeholder="+33601234567"
            label="Numéro de téléphone (avec indicateur régional)"
            type="string"
            value={showFormState.numeroTelephonePortable}
            onChange={(e) => modifyFormState('numeroTelephonePortable', e.target.value)}
          />
          <TextField
            fullWidth
            placeholder="claire.durrand@gmail.com"
            label="Adresse mail"
            type="string"
            value={showFormState.adresseMail}
            onChange={(e) => modifyFormState('adresseMail', e.target.value)}
          />
          <TextField
            fullWidth
            placeholder="fr"
            label="Code iso à deux lettres du pays de résidence"
            type="string"
            value={showFormState.codePaysIso2Residence}
            onChange={(e) => modifyFormState('codePaysIso2Residence', e.target.value)}
          />
          {showFormState.codePaysIso2Residence.toLowerCase() === 'fr' && (
            <>
              <TextField
                fullWidth
                placeholder="Toulouse"
                label="Ville de résidence"
                type="string"
                value={showFormState.villeResidenceLibelle}
                onChange={(e) => modifyFormState('villeResidenceLibelle', e.target.value)}
              />
              <TextField
                fullWidth
                placeholder="31000"
                label="Code postal de résidence"
                type="string"
                value={showFormState.villeResidenceCodePostal}
                onChange={(e) => modifyFormState('villeResidenceCodePostal', e.target.value)}
              />
              <TextField
                fullWidth
                placeholder="16"
                label="Numéro de voie"
                type="string"
                value={showFormState.numeroVoieResidence}
                onChange={(e) => modifyFormState('numeroVoieResidence', e.target.value)}
              />
              <InputLabel fullWidth id="select-codeTypeVoieResidence-label">
                Type de voie
              </InputLabel>
              <Select
                fullWidth
                label="Type de voie"
                labelId="select-codeTypeVoieResidence-label"
                id="select-codeTypeVoieResidence"
                value={showFormState.codeTypeVoieResidence}
                onChange={(e) => modifyFormState('codeTypeVoieResidence', e.target.value)}
              >
                {CodeTypeVoieResidence.map((codeTypeVoieResidence) => (
                  <MenuItem key={codeTypeVoieResidence.code} value={codeTypeVoieResidence.code}>
                    {codeTypeVoieResidence.label}
                  </MenuItem>
                ))}
              </Select>
              <TextField
                fullWidth
                placeholder="du Soleil"
                label="Libellé de voie"
                type="string"
                value={showFormState.libelleVoieResidence}
                onChange={(e) => modifyFormState('libelleVoieResidence', e.target.value)}
              />
              <InputLabel fullWidth id="select-lettreVoieResidence-label">
                Lettrage de voie
              </InputLabel>
              <Select
                fullWidth
                label="Lettrage de voie"
                labelId="select-lettreVoieResidence-label"
                id="select-lettreVoieResidence"
                value={showFormState.lettreVoieResidence}
                onChange={(e) => modifyFormState('lettreVoieResidence', e.target.value)}
              >
                <MenuItem value=""> </MenuItem>
                <MenuItem value="BIS">BIS</MenuItem>
                <MenuItem value="TER">TER</MenuItem>
                <MenuItem value="QUATER">QUATER</MenuItem>
                <MenuItem value="QUINQUIES">QUINQUIES</MenuItem>
              </Select>
              <TextField
                fullWidth
                placeholder="Bâtiment A"
                label="Complément d'adresse"
                type="string"
                value={showFormState.complementResidence}
                onChange={(e) => modifyFormState('complementResidence', e.target.value)}
              />
              <TextField
                fullWidth
                placeholder="Le Beyssat"
                label="Lieu dit"
                type="string"
                value={showFormState.lieuDitResidence}
                onChange={(e) => modifyFormState('lieuDitResidence', e.target.value)}
              />
            </>
          )}
          <h4
            style={{
              marginBottom: '0.5em',
              marginTop: '1em',
            }}
          >
            Informations bancaires
          </h4>
          <TextField
            fullWidth
            placeholder="FR7630004000031234567890143"
            label="IBAN"
            type="string"
            value={showFormState.iban}
            onChange={(e) => modifyFormState('iban', e.target.value)}
          />
          <TextField
            fullWidth
            placeholder="BNPAFRPP"
            label="BIC"
            type="string"
            value={showFormState.bic}
            onChange={(e) => modifyFormState('bic', e.target.value)}
          />
          <TextField
            fullWidth
            placeholder="Mme Claire DURRAND"
            label="Titulaire du compte (Civilité Prénom Nom)"
            type="string"
            value={showFormState.titulaire}
            onChange={(e) => modifyFormState('titulaire', e.target.value)}
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSend} label="Envoyer" disabled={loading}>
          <SendIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler">
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    if (needToShowButton()) {
      return (
        <>
          <Button onClick={handleClick} label="Entrer les informations pour l'URSSAF">
            <ListAlt />
          </Button>
          {avanceImmediateDialogView()}
        </>
      )
    }
    return <></>
  }

  return result()
}

export default AvanceImmediateInscriptionFormModalView
