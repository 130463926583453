import React from 'react'
import { Create, NumberInput, SimpleForm } from 'react-admin'

const PriceIncreaseRateCreate = (props) => {
  const currentYear = new Date().getFullYear()
  return (
    <Create {...props}>
      <SimpleForm redirect="show">
        <NumberInput
          source="year"
          label="Année de l'augmentation (année de début des contrat impactés)"
          step={1}
          defaultValue={currentYear}
          min={currentYear}
          fullWidth
        />
        <NumberInput
          source="priceIncreaseRatePercentage"
          label="Augmentation du prix (en %)"
          defaultValue={0}
          fullWidth
        />
      </SimpleForm>
    </Create>
  )
}

export default PriceIncreaseRateCreate
