import React from 'react'
import {
  SimpleShowLayout, BooleanField, Show, NumberField, TextField,
} from 'react-admin'

const MenuParametersShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="title" label="Titre" />
      <TextField source="link" label="Lien URL" />
      <NumberField source="priority" label="Priorité" />
      <BooleanField source="webview" label="Webview ?" />
    </SimpleShowLayout>
  </Show>
)

export default MenuParametersShow
