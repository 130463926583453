import React from 'react'
import {
  ArrayInput,
  BooleanInput,
  CheckboxGroupInput,
  DateInput,
  Edit,
  FileField,
  FileInput,
  FormTab,
  ImageField,
  ImageInput,
  NumberInput,
  ReferenceInput,
  SelectInput,
  SimpleFormIterator,
  TabbedForm,
  TextInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

import {
  banReasons,
  marketSegmentations,
  followUpEmailsSent,
  proposedServices,
} from './shared/professionalQualificationsChoices'

const ProfessionalEdit = (props) => (
  <Edit {...props}>
    <TabbedForm redirect="show">
      <FormTab label="Informations personnelles">
        <ReferenceInput
          label="État"
          source="professional.status"
          reference="enums"
          key="status"
          filter={{ enum: 'accountStatus' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <CheckboxGroupInput label="Banni pour" source="professional.banReasons" choices={banReasons} />
        <CheckboxGroupInput
          label="Segmentation de marché"
          source="professional.marketSegmentations"
          choices={marketSegmentations}
        />

        <BooleanInput source="professional.displayInSearchResults" label="Apparait dans les résultats de recherche" />
        <TextInput source="account.email" label="Email" />
        <TextInput source="profile.firstName" label="Prénom" />
        <TextInput source="profile.lastName" label="Nom" />
        <TextInput source="professional.address.streetAndNumber" label="N° et rue" />
        <TextInput source="professional.address.city" label="Ville" />
        <TextInput source="professional.address.zipCode" label="Code postal" />
        <TextInput source="profile.phone" label="Téléphone" />
        <DateInput source="profile.birthdate" label="Date de naissance" />

        <RichTextInput
          toolbar={[['bold', 'italic', 'underline', 'link']]}
          source="professional.description"
          label="Description de l'activité"
        />
        <ImageInput source="pictureFile" label="Photo de profil" accept="image/*">
          <ImageField source="profile.pictureFile" />
        </ImageInput>

        <ImageInput source="realisationsImages" label="Réalisation" accept="image/*" multiple>
          <ImageField source="path" />
        </ImageInput>

        <BooleanInput source="profile.subscribedNewsletter" label="Newsletter" />
        <BooleanInput source="profile.subscribedSms" label="SMS" />
        <BooleanInput source="profile.subscribedNotification" label="Notifications push" />

        <ReferenceInput
          label="Type"
          source="professional.billingProfile"
          reference="enums"
          key="billingProfile"
          filter={{ enum: 'billingProfile' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <BooleanInput source="entityRelation.professionalIsTrusted" label="Confiance 100%" />
        <BooleanInput source="entityRelation.professionalIsReactive" label="Réactif" />
        <TextInput source="entityRelation.professionalExternalId" label="Identifiant client" />

        <ReferenceInput
          label="Offre"
          source="serviceLevel"
          reference="enums"
          key="serviceLevel"
          filter={{ enum: 'serviceLevels' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>

        <CheckboxGroupInput
          label="Services proposés"
          source="professional.proposedServices"
          choices={proposedServices}
        />

        <ArrayInput source="entityRelation.fees" label="Taux commission">
          <SimpleFormIterator disableRemove disableAdd>
            <TextInput source="productFamilyId" label="Code" disabled />
            <NumberInput source="value" label="Valeur" />
          </SimpleFormIterator>
        </ArrayInput>

        <CheckboxGroupInput
          label="Emails de relance"
          source="professional.followUpEmailsSent"
          choices={followUpEmailsSent}
        />

        <RichTextInput source="professional.comment" label="Commentaires" toolbar={[['bold', 'italic', 'underline']]} />
      </FormTab>

      <FormTab label="Société">
        <TextInput source="professional.company.name" label="Nom de la société" />
        <NumberInput source="professional.company.registrationNumber" label="N° de SIRET ou d'AE" />
        <NumberInput source="professional.company.capital" label="Capital" />
        <ReferenceInput
          label="Forme juridique"
          source="professional.company.legalStatus"
          reference="enums"
          key="legalStatus"
          filter={{ enum: 'legalStatus' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <NumberInput source="professional.company.professionalSeniority" label="Nombre d'années d'expérience" />
        <TextInput source="professional.company.vatNumber" label="Numéro de TVA" />
        <TextInput source="professional.insurance.name" label="Nom de la compagnie d'assurance" />
        <TextInput source="professional.insurance.number" label="Numéro de l'assurance" />
        <NumberInput source="professional.hourlyPricing.priceMin" label="Tarif horaire minimum" />
        <NumberInput source="professional.hourlyPricing.priceMax" label="Tarif horaire maximum" />
        <TextInput source="professional.company.agreementNumber" label="Numéro de la déclaration SAP" />
        <FileInput source="sap_document" label="Documents SAP" accept="application/pdf">
          <FileField source="professional.company.agreementFile" target="_blank" />
        </FileInput>
      </FormTab>
      <FormTab label="Zones d'activité">
        <ArrayInput source="entityRelation.professionalZipCodes" label="Zones d'activité">
          <SimpleFormIterator>
            <TextInput source="zipCode" label="" />
          </SimpleFormIterator>
        </ArrayInput>
        <BooleanInput
          source="entityRelation.professionalZipCodesDeactivatableWithInactivity"
          label="Zones d'activités désactivables en cas de visites sans devis"
        />
      </FormTab>
      <FormTab label="Info générales">
        <NumberInput source="professional.staff.nbEmployees" label="Nombre de salariés" min={0} />
        <NumberInput source="professional.staff.nbAprentices" label="Nombres d'apprentis" min={0} />
        <NumberInput source="professional.staff.nbInterns" label="Nombre de stagiaires" min={0} />
        <NumberInput source="professional.revenues.totalRevenue" label="CA total estimé" />
        <NumberInput source="professional.revenues.maintenancePercentage" label="% entretien" min={0} max={100} />
        <NumberInput source="professional.revenues.maintenanceRevenue" label="CA entrerien" />
        <NumberInput source="professional.revenues.creationPercentage" label="% création" min={0} max={100} />
        <NumberInput source="professional.revenues.creationRevenue" label="CA création" />
        <ReferenceInput
          label="Coopérative"
          source="professional.cooperative"
          reference="enums"
          key="status"
          filter={{ enum: 'cooperative' }}
        >
          <SelectInput optionText="name" />
        </ReferenceInput>
        <DateInput source="professional.lastContactDate" label="Date du dernier contact" />
        <DateInput source="professional.nextContactDate" label="Prochain contact prévu le" />
      </FormTab>
    </TabbedForm>
  </Edit>
)

export default ProfessionalEdit
