import React, { useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'

import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import DialogContentText from '@material-ui/core/DialogContentText'
import { LocalOffer } from '@material-ui/icons'
import dataProvider from '../../../helpers/dataProvider'

const DisableCouponCodeModal = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const [loading, setLoading] = React.useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleConfirm = () => {
    const url = `/coupon-codes/${record.id}/disable`
    setLoading(true)
    dataProvider
      .post(url, {})
      .then(() => {
        setLoading(false)
        notify('La désactivation du code avantage a bien été effectuée', 'info')
        refresh()
      })
      .catch(() => {
        notify("Erreur: la désactivation du code avantage n'a pas pu être effectuée", 'info')
        setLoading(false)
      })
  }

  const needToShowButton = () => record.finalPaymentDate === undefined

  const confirmationDialog = () => (
    <Dialog open={showDialog}>
      <DialogTitle>Confirmer la désactivation du code avantage ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <b>&emsp;Attention ! Une fois confirmée, ce code ne pourra plus être utilisé</b>
        </DialogContentText>
        <DialogContentText>
          <b>&emsp;par les clients pour bénéficier de la réduction associée</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} label="Confirmer" disabled={loading}>
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler" disabled={loading}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = 'Désactiver le code avantage'
    if (needToShowButton()) {
      return (
        <>
          <Button onClick={handleClick} label={label} disabled={loading}>
            <LocalOffer />
          </Button>
          {confirmationDialog(label)}
        </>
      )
    }
    return <></>
  }

  return result()
}

export default DisableCouponCodeModal
