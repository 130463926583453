import React from 'react'
import { Show, SimpleShowLayout, TextField } from 'react-admin'
import MultipleChips from '../../components/multipleChips/MutlipleChips'

const CommercialAreasShow = (props) => (
  <Show {...props}>
    <SimpleShowLayout>
      <TextField source="nomZone" label="Région" />
      <TextField source="contactName" label="Prénom du contact" />
      <MultipleChips source="contactEmails" title="Emails du contact" />
      <MultipleChips source="contactPhones" title="Téléphones du contact" />
      <MultipleChips source="departementsNumbers" title="Départements" />
    </SimpleShowLayout>
  </Show>
)

export default CommercialAreasShow
