import React from 'react'
import {
  ChipField, Datagrid, List, NumberField, TextField,
} from 'react-admin'

const CouponCodeList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show" isRowSelectable={() => false}>
      <TextField source="name" label="Nom" />
      <ChipField source="code" label="Code" />
      <NumberField source="customerServiceFeesPercentage" label="Frais de service (en %)" />
      <NumberField source="discountPercentage" label="Réduction (en %)" />
    </Datagrid>
  </List>
)

export default CouponCodeList
