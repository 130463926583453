import React, { useState } from 'react'
import { Button, SimpleForm } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'

import SendIcon from '@material-ui/icons/Send'
import { DoubleArrow } from '@material-ui/icons'
import IconCancel from '@material-ui/icons/Cancel'
import { createForm } from 'final-form'
import TextField from '@material-ui/core/TextField'
import DialogContentText from '@material-ui/core/DialogContentText'
import dataProvider from '../../../helpers/dataProvider'

const TITLE = 'Lier à une inscription existante'

const AvanceImmediateInscriptionLinkToOtherInscriptionModalView = ({ record, customerId, customer1bisId }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [email, setEmail] = useState('')

  const handleClick = () => setShowDialog(true)

  const handleCloseClick = () => setShowDialog(false)

  const handleSend = () => {
    const body = {
      customerEmail: email,
      customerOffre1bisId: customer1bisId,
      customerProfileEntityId: customerId,
    }
    dataProvider
      .post('/avance-immediate/inscriptions/duplicate', body)
      .then(() => {
        window.alert('Les informations ont bien été envoyées.')
        window.location.reload()
      })
      .catch(() => {
        window.alert("Aucune inscription n'a été trouvée avec cet email.")
      })
  }

  const needToShowButton = () => !record.avanceImmediate || !record.avanceImmediate.informationProvided

  const avanceImmediateDialogView = () => (
    <Dialog open={showDialog} fullWidth maxWidth="sm">
      <DialogTitle>{TITLE}</DialogTitle>
      <DialogContent>
        <DialogContentText>Indiquez l'email de l'inscription à lier.</DialogContentText>
        <DialogContentText>
          <b>
            Attention ! Il s'agit du mail utilisé pour l'avance immédiate apparaissant dans l'onglet d'avance immédiate
            du client déjà inscrit. Il n'est pas nécessairement le même que celui de son inscription sur Cmonjardinier.
          </b>
        </DialogContentText>
        <SimpleForm
          form={createForm({
            onSubmit: () => {},
          })}
          toolbar={null}
        >
          <TextField
            fullWidth
            placeholder="thomas.duchemin@test.fr"
            label="Email"
            type="string"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
          />
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleSend} label="Envoyer">
          <SendIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler">
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    if (needToShowButton()) {
      return (
        <>
          <Button onClick={handleClick} label={TITLE}>
            <DoubleArrow />
          </Button>
          {avanceImmediateDialogView()}
        </>
      )
    }
    return <></>
  }

  return result()
}

export default AvanceImmediateInscriptionLinkToOtherInscriptionModalView
