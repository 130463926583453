import React from 'react'
import { Datagrid, List, TextField } from 'react-admin'
import MultipleChips from '../../components/multipleChips/MutlipleChips'

const CommercialAreasList = (props) => (
  <List {...props}>
    <Datagrid rowClick="show" isRowSelectable={() => false}>
      <TextField source="nomZone" label="Région" />
      <TextField source="contactName" label="Prénom du contact" />
      <MultipleChips source="contactEmails" label="Emails du contact" />
      <MultipleChips source="contactPhones" label="Téléphones du contact" />
    </Datagrid>
  </List>
)

export default CommercialAreasList
