import React from 'react'
import {
  BooleanInput, Edit, SimpleForm, TextInput,
} from 'react-admin'
import RichTextInput from 'ra-input-rich-text'

const Offre1bisCustomerEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextInput source="last_name" label="Nom" />
      <TextInput source="first_name" label="Prénom" />
      <TextInput source="email_address" label="Adresse mail" />
      <TextInput source="street_address" label="N° et rue" />
      <TextInput source="zip_code" label="Code postal" />
      <TextInput source="city" label="Ville" />

      <TextInput source="phone_number" label="Téléphone" />
      <RichTextInput source="comment" label="Commentaires" toolbar={[['bold', 'italic', 'underline']]} />
      <BooleanInput source="already_existing" label="Client CMJ (client de l'app pré-existant)" />
      <BooleanInput source="invoice_by_letter" label="Envoi de facture par lettre" />
    </SimpleForm>
  </Edit>
)

export default Offre1bisCustomerEdit
