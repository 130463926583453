import React from 'react'
import {
  Edit, NumberInput, SimpleForm, TextField,
} from 'react-admin'

const PriceIncreaseRateEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show">
      <TextField source="id" label="Identifiant" />
      <TextField source="year" label="Année de l'augmentation (année de début des contrat impactés)" />
      <NumberInput
        source="priceIncreaseRatePercentage"
        label="Augmentation du prix (en %)"
        defaultValue={0}
        fullWidth
      />
    </SimpleForm>
  </Edit>
)

export default PriceIncreaseRateEdit
