import * as React from 'react'
import {
  Create, SimpleForm, ReferenceInput, AutocompleteInput,
} from 'react-admin'
import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const redirect = (basePath, id, data) => `/${RESOURCE_IDENTIFIERS.PURCHASE_REQUEST}/${data.purchaseRequestId}/show/1`

const PurchaseRequestsProfessionalCreate = (props) => (
  <Create {...props}>
    <SimpleForm redirect={redirect}>
      <ReferenceInput
        label="Professionnel"
        source="professionalEntityRelationId"
        reference={RESOURCE_IDENTIFIERS.PROFESSIONAL}
        filterToQuery={(searchText) => ({ qName: searchText, qActiveOnly: true })}
      >
        <AutocompleteInput optionText="profile.fullname" />
      </ReferenceInput>
    </SimpleForm>
  </Create>
)

export default PurchaseRequestsProfessionalCreate
