import React from 'react'
import {
  Edit,
  SimpleForm,
  ReferenceField,
  TextField,
  BooleanField,
  DateField,
  ArrayField,
  Datagrid,
  Toolbar,
  SaveButton,
  SelectInput,
  TextInput,
  FormDataConsumer,
  BooleanInput,
} from 'react-admin'
import Divider from '@material-ui/core/Divider'
import { dateAndTimeWithWeekday } from '../../helpers/dateRender'

const PostEditToolbar = (props) => (
  <Toolbar {...props}>
    <SaveButton />
  </Toolbar>
)

const businessLineChoices = (formData) => {
  if (formData.businessLine === 'PONCTUEL') {
    return [
      { id: 'PONCTUEL', name: 'PONCTUEL' },
      { id: 'CREATION', name: 'CREATION' },
      { id: 'ELAGAGE', name: 'ELAGAGE' },
    ]
  }

  if (formData.businessLine === 'REGULIER') {
    return [
      { id: 'REGULIER', name: 'REGULIER' },
      { id: 'CREATION', name: 'CREATION' },
      { id: 'ELAGAGE', name: 'ELAGAGE' },
    ]
  }

  return [
    { id: 'CREATION', name: 'CREATION' },
    { id: 'ELAGAGE', name: 'ELAGAGE' },
  ]
}

const PurchaseRequestEdit = (props) => (
  <Edit {...props}>
    <SimpleForm redirect="show" toolbar={<PostEditToolbar />}>
      <TextField source="id" label="Identifiant" />
      <ReferenceField label="Nom" link="show" source="customerEntityRelationId" reference="customers">
        <TextField source="profile.lastName" />
      </ReferenceField>
      <ReferenceField label="Prénom" link="show" source="customerEntityRelationId" reference="customers">
        <TextField source="profile.firstName" />
      </ReferenceField>
      <FormDataConsumer>
        {({ formData, ...rest }) => (
          <SelectInput choices={businessLineChoices(formData)} source="businessLine" {...rest} />
        )}
      </FormDataConsumer>
      <BooleanInput source="onDemandProspecting" label="Prospection à la demande" />
      <TextField source="serviceLevel" label="Offre" />
      <TextField source="origin" label="Origine" />
      <TextField source="status" label="État" />
      <BooleanField source="visitRequired" label="Avec visite" />
      <DateField locales="fr-FR" source="createdDate" label="Date de création" />
      <TextField source="address.streetAndNumber" label="N° et rue" />
      <TextField source="address.zipCode" label="Code postal" />
      <TextField source="address.city" label="Ville" />
      <Divider />
      <TextInput multiline fullWidth source="description" label="Description" />
      <Divider />
      <ArrayField source="disponibilities" label="Disponibilités">
        <Datagrid>
          <DateField locales="fr-FR" options={dateAndTimeWithWeekday} source="from" label="Du" />
          <DateField locales="fr-FR" options={dateAndTimeWithWeekday} source="to" label="Au" />
        </Datagrid>
      </ArrayField>
    </SimpleForm>
  </Edit>
)

export default PurchaseRequestEdit
