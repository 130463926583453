const customerPositionings = [
  { id: 'ACCEPTED_FOR_OTHER_PRO', value: 'Devis accepté mais pour un autre pro' },
  { id: 'ACCEPTED_WAITING_FOR_PAYMENT', value: 'Devis accepté en attente de paiement' },
  { id: 'DECLINED', value: 'Devis décliné' },
  { id: 'LEVEL_1', value: 'Devis offre 1' },
  { id: 'RECALL', value: 'Devis à relancer' },
  { id: 'WAITING', value: 'Devis en attente' },
]

export default customerPositionings
