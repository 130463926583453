import React from 'react'
import {
  BooleanField, BooleanInput, Datagrid, DateField, Filter, List, TextField, TextInput,
} from 'react-admin'
import MultipleChips from '../../components/multipleChips/MutlipleChips'

const ZipcodeDescriptionFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nom de la ville" source="qCityName" alwaysOn />
    <TextInput label="Code postal" source="qZipcode" alwaysOn />
    <BooleanInput label="En attente de publication" source="qNotPublished" alwaysOn />
  </Filter>
)

const ZipcodeDescriptionList = (props) => (
  <List {...props} filters={<ZipcodeDescriptionFilter />}>
    <Datagrid rowClick="show" isRowSelectable={() => false}>
      <TextField source="cityName" label="Nom de la ville" />
      <MultipleChips source="zipcodes" label="Code postaux" />
      <BooleanField source="published" label="Description publiée" defaultValue="false" />
      <DateField source="upcomingPublicationDate" label="Date de publication" />
    </Datagrid>
  </List>
)

export default ZipcodeDescriptionList
