import React, { useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import AddCircle from '@material-ui/icons/AddCircle'
import SendIcon from '@material-ui/icons/Send'
import DialogContentText from '@material-ui/core/DialogContentText'
import dataProvider from '../../../helpers/dataProvider'

const PayRewardModal = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [points, setPoints] = useState(0)
  const [description, setDescription] = useState('')
  const refresh = useRefresh()
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSendClick = () => {
    dataProvider
      .post('/rewards/buy', {
        buyerProfileEntityRelationId: record.id,
        points,
        description,
      })
      .then((response) => {
        if (response.status === 200) {
          notify('Les points ont bien été utilisés', 'info')
          refresh()
        } else {
          notify("Erreur: les points n'ont pas pu être utilisés", 'error')
        }
      })
  }

  const enableButtonOpenModal = record && record.rewardPointsHistory && record.rewardPointsHistory.availablePoints

  const availablePoints = record && record.rewardPointsHistory && record.rewardPointsHistory.availablePoints
    ? record.rewardPointsHistory.availablePoints
    : 0

  const enableButtonSend = points > 0 && points <= availablePoints && description.length > 0

  const payRewardDialogView = () => (
    <Dialog
      open={showDialog}
      style={{
        paddingBottom: '4em',
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Utiliser les points de fidélité</DialogTitle>
      <DialogContent>
        <DialogContentText color={availablePoints - points < 0 ? 'error' : 'initial'}>
          Points restants après utilisation : <b> {availablePoints - points} </b>
        </DialogContentText>
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Nombre de points à utiliser"
          type="number"
          fullWidth
          aria-valuemax={availablePoints}
          aria-valuemin={0}
          value={points}
          onChange={(e) => setPoints(e.target.value)}
        />
        <TextField
          autoFocus
          margin="dense"
          id="name"
          label="Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseClick} label="Fermer">
          <IconCancel />
        </Button>
        <Button onClick={handleSendClick} label="Envoyer" disabled={!enableButtonSend}>
          <SendIcon />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => (
    <>
      <Button onClick={handleClick} label="Utiliser les points de fidélité" disabled={!enableButtonOpenModal}>
        <AddCircle />
      </Button>
      {payRewardDialogView()}
    </>
  )

  return result()
}

export default PayRewardModal
