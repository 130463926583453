import React, { useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'

import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import AttachMoneyIcon from '@material-ui/icons/AttachMoney'

import DialogContentText from '@material-ui/core/DialogContentText'
import dataProvider from '../../../helpers/dataProvider'

const CashInAvanceImmediateModalView = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const refresh = useRefresh()
  const notify = useNotify()
  const [loading, setLoading] = React.useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleConfirm = () => {
    const url = record.idInvoice
      ? `/avance-immediate/transactions/cashIn1bis/${record.idInvoice}`
      : `/avance-immediate/transactions/cashIn/${record.idOrder}`
    setLoading(true)
    dataProvider
      .post(url, {})
      .then(() => {
        setLoading(false)
        notify('La transaction a bien été encaissée', 'info')
        refresh()
      })
      .catch(() => {
        notify("Erreur: la transaction n'a pas pu être encaissée", 'info')
        setLoading(false)
      })
  }

  const needToShowButton = () => record.finalPaymentDate === undefined && record.idDemandePaiement

  const cashInDialogView = () => (
    <Dialog open={showDialog}>
      <DialogTitle>Encaisser l'avance immediate de la facture ?</DialogTitle>
      <DialogContent>
        <DialogContentText>
          <b>&emsp;Attention ! Une fois confirmée, cette facture sera</b>
        </DialogContentText>
        <DialogContentText>
          <b>&emsp;considérée comme réglée et le virement au pro sera réalisé</b>
        </DialogContentText>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleConfirm} label="Confirmer" disabled={loading}>
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler" disabled={loading}>
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    const label = "Encaisser l'avance immediate"
    if (needToShowButton()) {
      return (
        <>
          <Button onClick={handleClick} label={label} disabled={loading}>
            <AttachMoneyIcon />
          </Button>
          {cashInDialogView(label)}
        </>
      )
    }
    return <></>
  }

  return result()
}

export default CashInAvanceImmediateModalView
