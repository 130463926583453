const translatedStatuses = {
  PAYMENT_WAITING: 'En attente',
  PAYMENT_DONE: 'Effectué',
  PAYMENT_CANCELED: 'Annulé',
  PAYMENT_REFUNDED: 'Remboursé',
  PAYMENT_ERROR: 'Erreur',
}

const getPaymentStatus = (status) => translatedStatuses[status]

export default getPaymentStatus
