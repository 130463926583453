import React, { useState } from 'react'
import { Button } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import { ListAlt } from '@material-ui/icons'
import IconCancel from '@material-ui/icons/Cancel'

const AvanceImmediateInscriptionErrorModalView = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const needToShowButton = () => record.avanceImmediate && (record.avanceImmediate.errorBody || record.avanceImmediate.errors)

  const avanceImmediateDialogView = () => (
    <Dialog
      fullScreen
      open={showDialog}
      style={{
        paddingBottom: '4em',
      }}
    >
      <DialogTitle>Erreur renvoyée par l'URSSAF sur la dernière tentative d'envoie d'informations</DialogTitle>
      <DialogContent>
        <h3>Erreur retournée par l'URSSAF</h3>
        <pre>{record.avanceImmediate.errors}</pre>
        <h3>Informations envoyées</h3>
        <pre>{JSON.stringify(JSON.parse(record.avanceImmediate.sentBody), null, 2)}</pre>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseClick} label="Fermer">
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => {
    if (needToShowButton()) {
      return (
        <>
          <span>
            Une requête a été envoyée à l'URSSAF mais celle-ci a retourné une erreur.
            <Button onClick={handleClick} label="Erreurs retournée par l'URSSAF">
              <ListAlt />
            </Button>
          </span>
          {avanceImmediateDialogView()}
        </>
      )
    }
    return <></>
  }

  return result()
}

export default AvanceImmediateInscriptionErrorModalView
