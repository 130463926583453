export const convertSecondsToHoursMinutes = (seconds) => {
  const hours = Math.floor(seconds / 3600)
  const minutes = Math.floor((seconds - hours * 3600) / 60)
  return `${hours.toString().padStart(2, '0')}h${minutes.toString().padStart(2, '0')}`
}

export const displayDateTime = (date) => {
  if (!date) return ''
  return `${date.toLocaleDateString('fr-FR')} ${date.toLocaleTimeString('fr-FR')}`
}

export const displayMonthYear = (date) => {
  if (!date) return ''
  return `${date.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}`
}
