export const purchaseRequestStatus = {
  FINISHED: 'PR_FINISHED',
  ACCEPTED: 'PR_ACCEPTED',
}

export const purchaseBusinessLineType = {
  CREATION: 'Création',
  ELAGAGE: 'Elagage',
  PUNCTUAL: 'Entretien Ponctuel',
  RECURRING: 'Entretien Régulier',
}
