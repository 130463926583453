import React from 'react'
import {
  BooleanInput,
  Datagrid,
  DateField,
  DateInput,
  Filter,
  FunctionField,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'
import ExportModalView from '../../export/ExportModalView'

const ExportActions = () => (
  <TopToolbar>
    <ExportModalView path="billings" />
  </TopToolbar>
)

const PurchaseMaturityFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Rechercher par nom" source="qName" alwaysOn />
    <BooleanInput label="Erreur de paiement" source="qInError" alwaysOn />
    <DateInput label="De" source="qDateFrom" alwaysOn />
    <DateInput label="A" source="qDateTo" alwaysOn />
  </Filter>
)

const BillingsList = (props) => (
  <List
    {...props}
    sort={{ field: 'createdDate', order: 'DESC' }}
    filters={<PurchaseMaturityFilter />}
    actions={<ExportActions />}
  >
    <Datagrid>
      <ReferenceField label="Demande" link="show" source="purchaseRequest" reference="purchaserequests">
        <TextField source="id" />
      </ReferenceField>

      <ReferenceField label="Devis" link="show" source="purchase" reference="purchases">
        <TextField source="status" />
      </ReferenceField>

      <DateField locales="fr-FR" source="maturityDueDate" label="Date d'échéance" />
      <TextField source="businessLineType" label="Business Line" />

      <ReferenceField label="Acheteur" link="show" source="customerId" reference="customers">
        <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
      </ReferenceField>

      <ReferenceField label="Vendeur" link="show" source="proId" reference="professionals">
        <FunctionField render={(record) => `${record.profile.lastName} ${record.profile.firstName}`} />
      </ReferenceField>

      <NumberField source="quoteCustomerTTC" label="Devis TTC" options={{ style: 'currency', currency: 'EUR' }} />
      <NumberField
        source="paymentCustomerTTC"
        label="Paiment Client"
        options={{ style: 'currency', currency: 'EUR' }}
      />
      <TextField source="customerPaymentType" label="Paiement Client" />
      <TextField source="customerDepositStatus" label="Statut Avance Client" />
      <TextField source="customerPaymentStatus" label="Statut Paiement Client" />

      <NumberField source="proPaymentAmount" label="Reversement pro" options={{ style: 'currency', currency: 'EUR' }} />
      <TextField source="proMoneyOutStatus" label="Statut Reversement" />
    </Datagrid>
  </List>
)

export default BillingsList
