import React from 'react'
import {
  BooleanInput, Edit, SelectInput, SimpleForm, useShowController,
} from 'react-admin'

import customerPositionings from './CustomerPositionings'

const PurchaseEdit = (props) => {
  const purchase = useShowController(props).record
  const recurring = purchase && purchase.recurring
  return (
    <Edit {...props}>
      <SimpleForm redirect="show">
        <SelectInput
          label="Position du client"
          source="customerPositioning"
          choices={customerPositionings}
          optionText="value"
          allowEmpty
        />
        {recurring && <BooleanInput source="shouldBeRenewed" label="Activation du renouvellement automatique" />}
      </SimpleForm>
    </Edit>
  )
}

export default PurchaseEdit
