import React, { useEffect, useState } from 'react'
import { Button, SimpleForm } from 'react-admin'
import { createForm } from 'final-form'

import IconCancel from '@material-ui/icons/Cancel'
import ThumbUpIcon from '@material-ui/icons/ThumbUp'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import InputLabel from '@material-ui/core/InputLabel'
import MenuItem from '@material-ui/core/MenuItem'
import FormControl from '@material-ui/core/FormControl'
import Select from '@material-ui/core/Select'

import { PhoneInTalkOutlined } from '@material-ui/icons'
import DateFnsUtils from '@date-io/date-fns'
import { KeyboardDatePicker, MuiPickersUtilsProvider } from '@material-ui/pickers'
import moment from 'moment'
import dataProvider from '../../../helpers/dataProvider'
import {
  paymentReminderMode,
  paymentReminderModesLabels,
  paymentReminderType,
  paymentReminderTypesLabels,
} from '../../../enums/PaymentReminders'

const getDefaultPaymentReminderMode = (type) => {
  if (type === paymentReminderType.FORMAL_NOTICE) {
    return paymentReminderMode.FORMAL_NOTICE
  }
  if (type === paymentReminderType.ORDER_FOR_PAYMENT) {
    return paymentReminderMode.ORDER_FOR_PAYMENT
  }
  if (type === paymentReminderType.DO_NOT_REMIND) {
    return paymentReminderMode.DO_NOT_REMIND
  }
  return paymentReminderMode.EMAIL
}
const getDefaultPaymentReminderType = (record) => [
  paymentReminderType.FIRST,
  paymentReminderType.SECOND,
  paymentReminderType.THIRD,
  paymentReminderType.FORMAL_NOTICE,
  paymentReminderType.ORDER_FOR_PAYMENT,
  paymentReminderType.DO_NOT_REMIND,
].find((reminderType) => !record.paymentReminders.map((reminder) => reminder.type).includes(reminderType))

const getAvailablePaymentReminderModes = (type) => {
  if (type === paymentReminderType.FORMAL_NOTICE) {
    return [paymentReminderMode.FORMAL_NOTICE]
  }
  if (type === paymentReminderType.ORDER_FOR_PAYMENT) {
    return [paymentReminderMode.ORDER_FOR_PAYMENT]
  }
  if (type === paymentReminderType.DO_NOT_REMIND) {
    return [paymentReminderMode.DO_NOT_REMIND]
  }
  return [paymentReminderMode.EMAIL, paymentReminderMode.SMS, paymentReminderMode.PHONE_CALL]
}

const getAvailablePaymentReminderTypes = (record) => [
  paymentReminderType.FIRST,
  paymentReminderType.SECOND,
  paymentReminderType.THIRD,
  paymentReminderType.FORMAL_NOTICE,
  paymentReminderType.ORDER_FOR_PAYMENT,
  paymentReminderType.DO_NOT_REMIND,
].filter((type) => !record.paymentReminders.map((reminder) => reminder.type).includes(type))

const AddPaymentReminderModalView = ({ record, onPaymentReminderAdded }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [type, setType] = useState(getDefaultPaymentReminderType(record))
  const [mode, setMode] = useState(getDefaultPaymentReminderMode(type))
  const [date, setDate] = useState(new Date())
  const [nextPaymentReminderEstimatedDate, setNextPaymentReminderEstimatedDate] = useState(
    moment(new Date()).add(7, 'days').toDate()
  )
  useEffect(() => {
    setType(getDefaultPaymentReminderType(record))
  }, [record.paymentReminders])
  useEffect(() => {
    setMode(getDefaultPaymentReminderMode(type))
  }, [type])

  const handleDateChange = (newDate) => {
    setDate(newDate)
    setNextPaymentReminderEstimatedDate(moment(newDate).add(7, 'days').toDate())
  }

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleAddReminder = () => {
    const body = {
      date: moment(date).format('YYYY-MM-DD'),
      nextPaymentReminderEstimatedDate:
        type !== paymentReminderType.DO_NOT_REMIND
          ? moment(nextPaymentReminderEstimatedDate).format('YYYY-MM-DD')
          : null,
      type,
      mode,
    }
    dataProvider.post(`/purchase-maturities/${record.id}/payment-reminders`, body).then(() => {
      setShowDialog(false)
      onPaymentReminderAdded()
    })
  }

  const cashInDialogView = () => (
    <Dialog fullWidth open={showDialog}>
      <DialogTitle>Enregistrer une relance de règlement</DialogTitle>
      <DialogContent>
        <SimpleForm
          form={createForm({
            onSubmit: () => {},
          })}
          toolbar={null}
        >
          <>
            <FormControl fullWidth>
              <InputLabel id="payment-reminder-type-label">Type de relance</InputLabel>
              <Select
                fullWidth
                labelId="payment-reminder-type-label"
                id="payment-reminder-type-select"
                value={type}
                onChange={(event) => setType(event.target.value)}
              >
                {getAvailablePaymentReminderTypes(record).map((value) => (
                  <MenuItem key={value} value={value}>
                    {paymentReminderTypesLabels[value]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth style={{ marginTop: '1em' }}>
              <InputLabel id="payment-reminder-mode-label">Mode de relance</InputLabel>
              <Select
                fullWidth
                labelId="payment-reminder-mode-label"
                id="payment-reminder-mode-select"
                value={mode}
                onChange={(event) => setMode(event.target.value)}
              >
                {getAvailablePaymentReminderModes(type).map((value) => (
                  <MenuItem key={value} value={value}>
                    {paymentReminderModesLabels[value]}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
            <FormControl fullWidth>
              <MuiPickersUtilsProvider utils={DateFnsUtils}>
                <KeyboardDatePicker
                  variant="inline"
                  id="date"
                  label="Date de la relance"
                  value={date}
                  format="dd/MM/yyyy"
                  margin="normal"
                  onChange={(e) => handleDateChange(e)}
                  KeyboardButtonProps={{
                    'aria-label': 'change date',
                  }}
                />
              </MuiPickersUtilsProvider>
            </FormControl>
            {type !== paymentReminderType.DO_NOT_REMIND && (
              <FormControl fullWidth>
                <MuiPickersUtilsProvider utils={DateFnsUtils}>
                  <KeyboardDatePicker
                    variant="inline"
                    id="nextPaymentReminderEstimatedDate"
                    label="Date de la prochaine relance envisagée"
                    value={nextPaymentReminderEstimatedDate}
                    format="dd/MM/yyyy"
                    margin="normal"
                    onChange={(e) => setNextPaymentReminderEstimatedDate(e)}
                    KeyboardButtonProps={{
                      'aria-label': 'change date of next payment reminder',
                    }}
                  />
                </MuiPickersUtilsProvider>
              </FormControl>
            )}
          </>
        </SimpleForm>
      </DialogContent>
      <DialogActions>
        <Button onClick={handleAddReminder} label="Ajouter">
          <ThumbUpIcon />
        </Button>
        <Button onClick={handleCloseClick} label="Annuler">
          <IconCancel />
        </Button>
      </DialogActions>
    </Dialog>
  )

  return (
    <>
      <Button disabled={record.paymentReminders.length > 4} onClick={handleClick} label="Enregistrer une relance">
        <PhoneInTalkOutlined />
      </Button>
      {cashInDialogView()}
    </>
  )
}

export default AddPaymentReminderModalView
