import React from 'react'
import { Datagrid, List, TextField } from 'react-admin'

const PriceIncreaseRateList = (props) => (
  <List {...props} sort={{ field: 'year', order: 'ASC' }}>
    <Datagrid rowClick="show" isRowSelectable={() => false}>
      <TextField source="year" label="Année de l'augmentation" />
      <TextField source="priceIncreaseRatePercentage" label="Augmentation du prix (en %)" />
    </Datagrid>
  </List>
)

export default PriceIncreaseRateList
