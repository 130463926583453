import React, { useState } from 'react'
import { Button, useNotify, useRefresh } from 'react-admin'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import DialogContent from '@material-ui/core/DialogContent'
import DialogActions from '@material-ui/core/DialogActions'
import IconCancel from '@material-ui/icons/Cancel'
import TextField from '@material-ui/core/TextField'
import AddCircle from '@material-ui/icons/AddCircle'
import SendIcon from '@material-ui/icons/Send'
import DialogContentText from '@material-ui/core/DialogContentText'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import MenuItem from '@material-ui/core/MenuItem'
import dataProvider from '../../../helpers/dataProvider'
import { rewardPointsEarningType } from '../../../enums/RewardPointsEarningType'

const AddRewardPointsModal = ({ record }) => {
  const [showDialog, setShowDialog] = useState(false)
  const [points, setPoints] = useState(0)
  const [type, setType] = useState(Object.keys(rewardPointsEarningType)[0])
  const [description, setDescription] = useState(null)
  const refresh = useRefresh()
  const notify = useNotify()

  const handleClick = () => {
    setShowDialog(true)
  }

  const handleCloseClick = () => {
    setShowDialog(false)
  }

  const handleSendClick = () => {
    dataProvider
      .post('/rewards/add-points', {
        buyerProfileEntityRelationId: record.id,
        points,
        type,
        description,
      })
      .then((response) => {
        if (response.status === 200) {
          notify('Les points ont bien été ajoutés', 'info')
          refresh()
        } else {
          notify("Erreur: les points n'ont pas pu être ajoutés", 'error')
        }
      })
  }

  const availablePoints = record && record.rewardPointsHistory && record.rewardPointsHistory.availablePoints
    ? record.rewardPointsHistory.availablePoints
    : 0

  const enableButtonSend = points > 0 && type.length > 0

  const addRewardPointsDialogView = () => (
    <Dialog
      open={showDialog}
      style={{
        paddingBottom: '4em',
      }}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Ajouter les points de fidélité</DialogTitle>
      <DialogContent>
        <DialogContentText>
          Points après ajout : <b> {Number(availablePoints) + Number(points)} </b>
        </DialogContentText>
        <TextField
          autoFocus
          id="name"
          label="Nombre de points à ajouter"
          type="number"
          fullWidth
          aria-valuemax={availablePoints}
          aria-valuemin={0}
          value={points}
          onChange={(e) => setPoints(e.target.value)}
          style={{ marginBottom: '30px' }}
        />
        <InputLabel fullWidth id="reward-points-type-label">
          Raison de l'ajout de points
        </InputLabel>
        <Select
          label="Raison de l'ajout de points"
          labelId="reward-points-type-label"
          id="select-reward-points-type"
          fullWidth
          value={type}
          onChange={(e) => setType(e.target.value)}
        >
          {Object.keys(rewardPointsEarningType)
            .filter((key) => key !== 'ORDER')
            .map((key) => (
              <MenuItem key={key} value={key}>
                {rewardPointsEarningType[key]}
              </MenuItem>
            ))}
        </Select>

        <TextField
          margin="dense"
          id="name"
          label="Description"
          type="text"
          fullWidth
          value={description}
          onChange={(e) => setDescription(e.target.value)}
          style={{ marginTop: '30px' }}
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={handleCloseClick} label="Fermer">
          <IconCancel />
        </Button>
        <Button onClick={handleSendClick} label="Envoyer" disabled={!enableButtonSend}>
          <SendIcon />
        </Button>
      </DialogActions>
    </Dialog>
  )

  const result = () => (
    <>
      <Button onClick={handleClick} label="Ajouter des points de fidélité">
        <AddCircle />
      </Button>
      {addRewardPointsDialogView()}
    </>
  )

  return result()
}

export default AddRewardPointsModal
