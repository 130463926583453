// In src/App.js
import React from 'react'
import { Admin, Resource } from 'react-admin'
import dotenv from 'dotenv'
import SupervisedUserCircleIcon from '@material-ui/icons/SupervisedUserCircle'
import PhonelinkSetupIcon from '@material-ui/icons/PhonelinkSetup'
import polyglotI18nProvider from 'ra-i18n-polyglot'
import {
  Cached,
  Euro,
  LocalOffer,
  LocationCity,
  Map,
  Payment,
  People,
  PeopleOutline,
  Receipt,
  Schedule,
  ShoppingBasket,
} from '@material-ui/icons'

import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from './config/resources'
import { UserSnap } from './config/vendors'
import { englishMessages } from './i18n/en'
import { frenchMessages } from './i18n/fr'
import CmjTheme from './themes/CmjTheme'

import HttpProvider from './providers/http/provider'
import authProvider from './providers/http/authProvider'
import addUploadCapabilities from './providers/http/addUploadCapabilities'

import EnumsShow from './pages/enums/EnumsShow'
import { LoginPage } from './pages/login/LoginPage'
import AppointmentList from './pages/appointments/AppointmentList'
import DashBoard from './pages/dashboard/DashBoard'
import PurchaseMaturitiesList from './pages/purchasematurity/PurchaseMaturitiesList'
import WalletShow from './pages/wallets/WalletShow'
import Parameters from './pages/parameters/Parameters'
import PurchaseRequestsProfessionalCreate from './pages/purchaserequestprofessional/PurchaseRequestsProfessionalCreate'
import BillingsList from './pages/billings/BillingsList'
import CustomerList from './pages/customers/CustomerList'
import CustomerShow from './pages/customers/CustomerShow'
import CustomerEdit from './pages/customers/CustomerEdit'
import ProfessionalList from './pages/professionals/ProfessionalList'
import ProfessionalShow from './pages/professionals/ProfessionalShow'
import ProfessionalEdit from './pages/professionals/ProfessionalEdit'
import PurchaseRequestList from './pages/purchaserequests/PurchaseRequestList'
import PurchaseRequestShow from './pages/purchaserequests/PurchaseRequestShow'
import PurchaseRequestEdit from './pages/purchaserequests/PurchaseRequestEdit'
import PurchaseList from './pages/purchases/PurchaseList'
import PurchaseShow from './pages/purchases/PurchaseShow'
import PurchaseEdit from './pages/purchases/PurchaseEdit'
import MenuParametersList from './pages/menuparameters/MenuParametersList'
import MenuParametersShow from './pages/menuparameters/MenuParametersShow'
import MenuParametersUpdate from './pages/menuparameters/MenuParametersEdit'
import MenuParametersCreate from './pages/menuparameters/MenuParametersCreate'
import Offre1bisCustomersList from './pages/offre1bis/customers/Offre1bisCustomersList'
import Offre1bisCustomerShow from './pages/offre1bis/customers/Offre1bisCustomerShow'
import Offre1bisCustomerEdit from './pages/offre1bis/customers/Offre1bisCustomerEdit'
import Offre1BisInvoicesList from './pages/offre1bis/invoices/Offres1BisInvoicesList'
import AvanceImmediateTransactionsList from './pages/avanceimmediate/AvanceImmediateTransactionsList'
import PurchaseMaturityShow from './pages/purchasematurity/PurchaseMaturityShow'
import CommercialAreasList from './pages/commercialareas/CommercialAreasList'
import CommercialAreasShow from './pages/commercialareas/CommercialAreasShow'
import CommercialAreasEdit from './pages/commercialareas/CommercialAreasEdit'
import CommercialAreasCreate from './pages/commercialareas/CommercialAreasCreate'
import Offre1bisInvoiceShow from './pages/offre1bis/invoices/Offre1bisInvoiceShow'
import Offre1bisInvoiceEdit from './pages/offre1bis/invoices/Offre1bisInvoiceEdit'
import CouponCodeList from './pages/couponcodes/CouponCodeList'
import CouponCodeCreate from './pages/couponcodes/CouponCodeCreate'
import CouponCodeShow from './pages/couponcodes/CouponCodeShow'
import CouponCodeEdit from './pages/couponcodes/CouponCodeEdit'
import ZipcodeDescriptionList from './pages/zipcodedescriptions/ZipcodeDescriptionList'
import ZipcodeDescriptionShow from './pages/zipcodedescriptions/ZipcodeDescriptionShow'
import ZipcodeDescriptionEdit from './pages/zipcodedescriptions/ZipcodeDescriptionEdit'
import ZipcodeDescriptionCreate from './pages/zipcodedescriptions/ZipcodeDescriptionCreate'
import PriceIncreaseRateCreate from './pages/priceincreaserates/PriceIncreaseRateCreate'
import PriceIncreaseRateEdit from './pages/priceincreaserates/PriceIncreaseRateEdit'
import PriceIncreaseRateList from './pages/priceincreaserates/PriceIncreaseRateList'
import PriceIncreaseRateShow from './pages/priceincreaserates/PriceIncreaseRateShow'

dotenv.config()

// Const i18nProvider = polyglotI18nProvider(locale =>
//         locale === 'fr' ? frenchMessages : englishMessages,
//     'en' // Default locale
// );
const messages = {
  fr: frenchMessages,
  en: englishMessages,
}
const i18nProvider = polyglotI18nProvider((locale) => messages[locale], 'fr')

const App = () => (
  <>
    <UserSnap />
    <Admin
      dashboard={DashBoard}
      i18nProvider={i18nProvider}
      dataProvider={addUploadCapabilities(HttpProvider)}
      authProvider={authProvider}
      loginPage={LoginPage}
      theme={CmjTheme}
    >
      {(permissions) => [
        permissions === 'ADMIN' ? (
          <Resource name={RESOURCE_IDENTIFIERS.ENUM} show={EnumsShow} icon={SupervisedUserCircleIcon} />
        ) : null,
        <Resource name={RESOURCE_IDENTIFIERS.WALLET} show={WalletShow} icon={SupervisedUserCircleIcon} />,
        <Resource name={RESOURCE_IDENTIFIERS.NOTIFICATIONS} icon={SupervisedUserCircleIcon} />,
        <Resource
          name={RESOURCE_IDENTIFIERS.CUSTOMER}
          list={CustomerList}
          show={CustomerShow}
          edit={CustomerEdit}
          icon={PeopleOutline}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.OFFRE_1BIS}
          list={Offre1bisCustomersList}
          show={Offre1bisCustomerShow}
          edit={Offre1bisCustomerEdit}
          icon={PeopleOutline}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.PROFESSIONAL}
          list={ProfessionalList}
          show={ProfessionalShow}
          edit={ProfessionalEdit}
          icon={People}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.PURCHASE_REQUEST}
          list={PurchaseRequestList}
          show={PurchaseRequestShow}
          edit={PurchaseRequestEdit}
          icon={ShoppingBasket}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.APPOINTMENT_VISIT}
          list={AppointmentList}
          show={PurchaseRequestShow}
          icon={Schedule}
        />,
        <Resource name={RESOURCE_IDENTIFIERS.APPOINTMENT_INTERVENTION} list={AppointmentList} icon={Schedule} />,
        <Resource
          name={RESOURCE_IDENTIFIERS.PURCHASE_REQUEST_PROFESSIONAL}
          icon={SupervisedUserCircleIcon}
          create={PurchaseRequestsProfessionalCreate}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.PURCHASE_MATURITY}
          list={PurchaseMaturitiesList}
          show={PurchaseMaturityShow}
          icon={Euro}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.INVOICES_1_BIS}
          list={Offre1BisInvoicesList}
          show={Offre1bisInvoiceShow}
          edit={Offre1bisInvoiceEdit}
          icon={Euro}
        />,
        <Resource name={RESOURCE_IDENTIFIERS.BILLINGS} list={BillingsList} icon={Euro} />,
        <Resource
          name={RESOURCE_IDENTIFIERS.AVANCE_IMMEDIATE_TRANSACTIONS}
          list={AvanceImmediateTransactionsList}
          icon={Payment}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.PURCHASE}
          list={PurchaseList}
          show={PurchaseShow}
          edit={PurchaseEdit}
          icon={Receipt}
        />,
        <Resource name={RESOURCE_IDENTIFIERS.BUSINESS_LINE} icon={SupervisedUserCircleIcon} />,
        <Resource name={RESOURCE_IDENTIFIERS.ORDER} icon={SupervisedUserCircleIcon} />,
        <Resource name={RESOURCE_IDENTIFIERS.PARAMETERS} list={Parameters} />,
        <Resource
          name={RESOURCE_IDENTIFIERS.COMMERCIAL_AREAS}
          list={CommercialAreasList}
          show={CommercialAreasShow}
          edit={CommercialAreasEdit}
          create={CommercialAreasCreate}
          icon={Map}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.COUPON_CODES}
          list={CouponCodeList}
          show={CouponCodeShow}
          edit={CouponCodeEdit}
          create={CouponCodeCreate}
          icon={LocalOffer}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.ZIPCODE_DESCRIPTIONS}
          list={ZipcodeDescriptionList}
          show={ZipcodeDescriptionShow}
          edit={ZipcodeDescriptionEdit}
          create={ZipcodeDescriptionCreate}
          icon={LocationCity}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.PRICE_INCREASE_RATES}
          list={PriceIncreaseRateList}
          show={PriceIncreaseRateShow}
          edit={PriceIncreaseRateEdit}
          create={PriceIncreaseRateCreate}
          icon={Cached}
        />,
        <Resource
          name={RESOURCE_IDENTIFIERS.MENU_PARAMETERS}
          list={MenuParametersList}
          show={MenuParametersShow}
          edit={MenuParametersUpdate}
          create={MenuParametersCreate}
          icon={PhonelinkSetupIcon}
        />,
      ]}
    </Admin>
  </>
)
export default App
