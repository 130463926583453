import React from 'react'
import {
  BooleanField,
  Datagrid,
  DateField,
  DateInput,
  EditButton,
  EmailField,
  Filter,
  List,
  NumberField,
  ReferenceField,
  TextField,
  TextInput,
  TopToolbar,
} from 'react-admin'
import ExportModalView from '../../export/ExportModalView'
import PushNotifModalView from './PushNotifModalView'

const ProfessionalFilter = (props) => (
  <Filter {...props}>
    <TextInput label="Nom" source="qName" alwaysOn />
    <TextInput label="Id" source="qId" alwaysOn />
    <TextInput label="Email" source="qEmail" alwaysOn />
    <DateInput label="Début" source="qStart" alwaysOn />
    <DateInput label="Fin" source="qEnd" alwaysOn />
  </Filter>
)

const ExportActions = () => (
  <>
    <TopToolbar>
      <PushNotifModalView />
      <ExportModalView path="professionals" />
      <ExportModalView path="professionals-activity" name="Export csv stats" />
    </TopToolbar>
  </>
)

const ProfessionalList = (props) => (
  <List
    {...props}
    actions={<ExportActions />}
    sort={{ field: 'createdDate', order: 'DESC' }}
    filters={<ProfessionalFilter />}
  >
    <Datagrid rowClick="show">
      <DateField locales="fr-FR" source="createdDate" label="Date de création" />
      <ReferenceField label="Nom" link="show" source="id" reference="professionals">
        <TextField source="profile.lastName" />
      </ReferenceField>
      <ReferenceField label="Prénom" link="show" source="id" reference="professionals">
        <TextField source="profile.firstName" />
      </ReferenceField>
      <TextField source="professional.company.name" label="Société" />
      <TextField source="professional.billingProfile" label="Type" />
      <TextField source="professional.status" label="État" />
      <EmailField source="account.email" label="Mail" />
      <TextField source="professional.address.region" label="Région" />
      <TextField source="professional.company.legalStatus" label="Statut Juridique" />
      <NumberField
        source="professional.company.capital"
        label="Capital"
        options={{ style: 'currency', currency: 'EUR' }}
      />
      <EditButton rowClick="edit" />
    </Datagrid>
  </List>
)

export default ProfessionalList
