import React from 'react'
import { Button, useNotify } from 'react-admin'
import { Domain, DomainDisabled } from '@material-ui/icons'
import dataProvider from '../../../helpers/dataProvider'

const ToggleUseAvanceImmediateButton = ({ record }) => {
  const notify = useNotify()
  const handleChangeUsageAvanceImmediate = (useAvanceImmediate, inscriptionId) => {
    dataProvider
      .put(`/avance-immediate/${inscriptionId}/usage`, useAvanceImmediate)
      .then((response) => {
        if (response.status === 200) {
          window.location.reload()
        }
      })
      .catch((error) => {
        if (error.response && error.response.data && error.response.data) {
          if (error.response.data.error === 'NO_AVANCE_IMMEDIATE_WITH_MANDATE') {
            notify(
              'Erreur: avance immédiate impossible pour un client avec un mandat de prélèvement, veuillez le désactiver et réessayez',
              'error'
            )
          } else if (error.response.data.message) {
            notify(`Erreur: ${error.response.data.message}`, 'error')
          } else {
            notify("Erreur inconnue: l'utilisation de l'avance immédiate n'a pas pu être modifiée", 'error')
          }
        } else {
          notify("Erreur inconnue: l'utilisation de l'avance immédiate n'a pas pu être modifiée", 'error')
        }
      })
  }
  if (record.avanceImmediate && record.avanceImmediate.informationProvided) {
    return (
      <div
        style={{
          marginTop: '24px',
        }}
      >
        <p>
          Avance immédiate actuellement{' '}
          {record.avanceImmediate.usesAvanceImmediate ? <b>activée</b> : <b>désactivée</b>} pour ce compte
        </p>
        <Button
          style={{ marginBottom: '1rem' }}
          onClick={() => handleChangeUsageAvanceImmediate(!record.avanceImmediate.usesAvanceImmediate, record.avanceImmediate.id)}
          label={
            record.avanceImmediate.usesAvanceImmediate ? "Désactiver l'avance immédiate" : "Activer l'avance immédiate"
          }
        >
          {record.avanceImmediate.usesAvanceImmediate ? <DomainDisabled /> : <Domain />}
        </Button>
      </div>
    )
  }
  return <div />
}

export default ToggleUseAvanceImmediateButton
