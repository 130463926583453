import { IDENTIFIERS as RESOURCE_IDENTIFIERS } from '../../config/resources'

const convertFileToBase64 = (file) => new Promise((resolve, reject) => {
  const reader = new FileReader()
  reader.readAsDataURL(file.rawFile)

  reader.onload = () => resolve(reader.result)
  reader.onerror = reject
})

const addUploadCapabilities = (requestHandler) => async (type, resource, params) => {
  let newParams = params
  if (
    (type === 'UPDATE' || type === 'CREATE')
    && (resource === RESOURCE_IDENTIFIERS.CUSTOMER
      || resource === RESOURCE_IDENTIFIERS.PROFESSIONAL
      || resource === RESOURCE_IDENTIFIERS.ZIPCODE_DESCRIPTIONS)
  ) {
    if (
      params.data
      && (params.data.pictureFile || params.data.sap_document || params.data.realisationsImages || params.data.pictureList)
    ) {
      if (params.data.pictureFile) {
        const myFile = params.data.pictureFile
        if (myFile.rawFile instanceof File) {
          const picture64 = await convertFileToBase64(myFile)
          newParams = {
            ...newParams,
            data: {
              ...newParams.data,
              upload_encoded: picture64,
            },
          }
        }
      }

      if (params.data.sap_document) {
        const myFile = params.data.sap_document
        if (myFile.rawFile instanceof File) {
          const sap64 = await convertFileToBase64(myFile)
          newParams = {
            ...newParams,
            data: {
              ...newParams.data,
              upload_encoded_sap: sap64,
            },
          }
        }
      }

      if (params.data.realisationsImages) {
        const myFiles = params.data.realisationsImages
        const newUploads = []
        const oldUploads = []
        for (const myFile of myFiles) {
          // If it is a new file, convert to base64 and send it in a new field
          if (myFile.rawFile instanceof File) {
            const sap64 = await convertFileToBase64(myFile)
            newUploads.push(sap64)
          } else {
            // If this is an existing file, override the realisationsImages
            oldUploads.push(myFile)
          }
        }
        newParams = {
          ...newParams,
          data: {
            ...newParams.data,
            realisationsImages: oldUploads,
            upload_encoded_realisations: newUploads,
          },
        }
      }

      if (params.data.pictureList) {
        const newPictureList = await Promise.all(
          params.data.pictureList.map(async (picture) => {
            if (picture.file && picture.file.rawFile instanceof File) {
              return {
                ...picture,
                file: undefined,
                uploadEncodedFile: await convertFileToBase64(picture.file),
              }
            }
            return picture
          })
        )
        newParams = {
          ...newParams,
          data: {
            ...newParams.data,
            pictureList: newPictureList,
          },
        }
      }
    }

    return requestHandler(type, resource, {
      ...newParams,
      data: {
        ...newParams.data,
      },
    })
  }
  return requestHandler(type, resource, newParams)
}

export default addUploadCapabilities
