import React from 'react'
import {
  ChipField,
  DateField,
  NumberField,
  RichTextField,
  Show,
  SimpleShowLayout,
  TextField,
  useShowController,
} from 'react-admin'
import DisableCouponCodeModal from './components/DisableCouponCodeModal'

const CouponCodeShow = (props) => {
  const { record } = useShowController(props)
  const isDisabled = record && record.disabled
  return (
    <Show {...props}>
      <SimpleShowLayout>
        <TextField source="name" label="Nom (affiché pour le client)" />
        <ChipField source="code" label="Code" />
        <NumberField source="customerServiceFeesPercentage" label="Frais de service (en %)" />
        <NumberField source="discountPercentage" label="Réduction (en %)" />
        <RichTextField source="description" label="Description interne (facultatif)" />
        <DateField locales="fr-FR" source="createdDate" label="Date de création" />
        {isDisabled ? (
          <DateField locales="fr-FR" source="disabledDate" label="Date de désactivation" />
        ) : (
          <DisableCouponCodeModal />
        )}
      </SimpleShowLayout>
    </Show>
  )
}

export default CouponCodeShow
